import { Avatar, IconButton, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Plus, Users } from 'react-feather';
import ScrollBar from 'react-perfect-scrollbar';
import UserCard from './user';
import { ChatUser } from './interface';
import { getCurrentTimestamp } from '../../Logics/DateFunc';
import { MDBBtn } from 'mdb-react-ui-kit';
import NoData from '../../utils/noData';
import toast from 'react-hot-toast';
import CardSkeleton from '../../utils/cardSkeleton';
import { getSingleDoc } from '../../Logics/getDoc';
import { AddData } from '../../Logics/addData';
import { User } from '../../app.interface';
import { fireAfterStop } from '../../Logics/date';
import { ClipLoader } from 'react-spinners';
import { docQr } from '../../Logics/docQr_ORGate';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import useUser from '../../hooks/useUser';
import { useSelector } from 'react-redux';
interface Props{
    room:any,
    setLocalUsers:(users:ChatUser[])=>void
    setCurrentChatRoom:(prop:any)=>void
}
const SideUsers:React.FC<Props>=({room,setLocalUsers,setCurrentChatRoom})=>{
    const [users,setUsers]=useState<ChatUser[]>([])
    const saved_user=useUser()
const [isLoading,setIsLoading]=useState<boolean>(true)
const isAdmin=useSelector((state:any)=>state.isAdmin.isAdmin)

async function fetchUsers(){
try{
const users=await getSingleDoc(room.roomId+"_Users");
if(users.filter((e:ChatUser)=>e.uid===saved_user.user.uid).length <= 0 && !isAdmin && !room.isPublic){
    toast.error("Sorry you don't have a sit in room selected");
    setCurrentChatRoom(undefined)
}
setUsers([...users]);
setLocalUsers([...users]);
}
catch(err:any){
    toast.error(err?.message || "Something went wrong")
}
finally{
    setIsLoading(false);
}
}

const [isCreating,setIsCreating]=useState<boolean>(false);

async function createUser(user:User){
    setIsCreating(true);
    console.log(room.roomId)
    if(users.filter((e)=>e.uid===user.uid).length > 0){
        toast.error("user already exists");
setUser(undefined);
(document.querySelector("#search") as HTMLInputElement).value="";
        return 
    }
    try{
await AddData(collection(db,room.roomId+"_Users"),{
    name:user.username || "",
    email:user.email || "",
    picture:user.picture || "",
    uid:user.uid || "",
    lastSeen:null,
    blocked:false,
    isTyping:false
})
fetchUsers();
setUser(undefined);
(document.querySelector("#search") as HTMLInputElement).value="";
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        setIsLoading(false);
    }
}

const [user,setUser]=useState<User>()
const [searching,setSearching]=useState<boolean>(false);
const searchUser=async (name:string)=>{
    setSearching(true)
    console.log("searching",name)
const data=await docQr("Users",{
    max:1,
    whereClauses:[
        {
            field:"username",
            operator:"==",
            value:name
        },
        {
            field:"email",
            operator:"==",
            value:name
        }
    ]
})
if(data.length>0){
    setUser(data[0]);
}
console.log('search result',data)
setSearching(false)
}
const ref=useRef(false)
useEffect(()=>{
    if(!ref.current){
    fetchUsers()
    ref.current=true;
    }

},[])
    return (
        <>
        <div className='sideUsers'>

           {!room.isPublic ? <><div className='d-flex align-items-center justify-content-between' style={{gap:10}}>
<TextField id='search' onChange={(e)=>{
    const {target:{value}}=e;
    // console.log(value);
    fireAfterStop(()=>searchUser(value),1500)

}} type='search' size='small' style={{flexGrow:"1"}} placeholder='Search & Add user (email or name)'/>
<IconButton style={{background:"var(--blue)",color:"white",fontSize:'small'}}>{users.length}<Users size={14} color='white'/></IconButton>
</div>
<ScrollBar style={{height:"82vh",padding:5}}>
{searching && 
<div style={{padding:15}}>
<ClipLoader />
</div>}

{user && isAdmin && <><div className='text-center' style={{margin:"0 auto",borderRadius:20,padding:10,boxShadow:"1px 1px 10px 0px lightgrey"}}>
    <div className='d-flex justify-content-center'><Avatar/></div>
    <span>{user.username}</span><br/>
    <span>{user.email}</span><br/>
    <MDBBtn color='dark' rounded style={{boxShadow:"none"}} onClick={()=>{
        if(user)createUser(user as User);
}}>{isCreating ?<ClipLoader size={15} color="white"/>: "Add user "+user?.username}</MDBBtn>
</div><br/></>}


{users.length===0 && !isLoading && !user && <div>
    <NoData>No user added to this chat Room</NoData>
    </div>}
<br/>
{users.map((user:ChatUser,i:number)=><UserCard isAdmin={isAdmin} room={room} key={i} user={user} fetchUsers={fetchUsers}/>)}
{isLoading && [1,2,3,4,5].map((e)=><CardSkeleton key={e}/>)}
</ScrollBar></>
 : 
 <NoData image='/pubGroup.avif'>
     This room is public
 </NoData>
 }
        </div>
        </>
    )
}

export default SideUsers;