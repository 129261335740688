import React, { useState } from 'react'
import { Avatar, IconButton } from '@mui/material';
import Bold from '../utils/Bold';
import { MDBBadge, MDBInput } from 'mdb-react-ui-kit';
import { Bell, Menu } from 'react-feather';
import LogoAndText from '../utils/LogoAndText';
import useInnerWidth from '../hooks/useInnerWidth';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import SideNav from './pub/sideNav';
import useUser from '../hooks/useUser';
import { useLocation } from 'react-router-dom';
const DashboardNav:React.FC=()=>{
    const [menu,setMenu]=useState<boolean>(false)
    const width=useInnerWidth();
    const location=useLocation()
    const {user}=useUser();
    const [notification,setNotification]=useState<boolean>(false);
    return (<>
    {location.pathname!=='/Chat' && <nav className='nav d-flex align-items-center justify-content-between'>
{width > 700 && <div  style={{gap:10}} className='m-none d-flex align-items-center'>
    <Avatar src={user?.picture || "/images/user.png"}/>
    <Bold>{user.username}</Bold>
</div>}
{width < 700 && <LogoAndText showText/>}

<div  style={{gap:10}} className='d-flex align-items-center'>

    <IconButton onClick={()=>{
      setNotification(true);
    }}><Bell/></IconButton>
    {width < 700 && <IconButton onClick={()=>setMenu(true)}><Menu/></IconButton>}
{width > 700 && <MDBInput label='Search Aa..'/>}
</div>
    </nav>}



    <Offcanvas isOpen={notification} direction='end' className='notificationBackDrop' backdropClassName='backDrop' toggle={()=>setNotification(!notification)} onHide={()=>setNotification(false)}>
    <OffcanvasHeader className='notificationBackDrop' toggle={()=>setNotification(!notification)} closeButton>
      <LogoAndText showText/>
    </OffcanvasHeader>
    <OffcanvasBody className='notificationBackDrop' style={{overflowX:"hidden",overflowY:"auto"}}>
    No Notification Available
    </OffcanvasBody>
  </Offcanvas>


    <Offcanvas direction='end' isOpen={menu} backdropClassName='backDrop' toggle={()=>setMenu(!menu)} onHide={()=>setMenu(false)}>
    <OffcanvasHeader toggle={()=>setMenu(!menu)} closeButton>
      <LogoAndText showText/>
    </OffcanvasHeader>
    <OffcanvasBody style={{overflowX:"hidden",overflowY:"auto"}}>
    <SideNav clickCallBack={()=>setMenu(false)}/>
    </OffcanvasBody>
  </Offcanvas>
    </>
    )
}

export default DashboardNav;