import React from 'react'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment)

const CalendarMain:React.FC=()=>{
    return (
        <>


<div>
    <Calendar
      localizer={localizer}
    //   events={myEventsList}
      startAccessor="start"
      endAccessor="end"
      style={{ height: '80vh',width:"100%" }}
    />
  </div>
  </>
    )
}

export default CalendarMain;