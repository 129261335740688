import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

const SkeletonLoader = () => {
    return (
        <Box className='box' p={2}>
            <Box display="flex" gap={2} alignItems="center">
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="text" width="60%" height={30} />
            </Box>
            <Skeleton variant="rectangular" width="100%" height={1} style={{ margin: '10px 0' }} />
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    );
}

export default SkeletonLoader;
