import React, { useState } from 'react'
import { Task, User } from '../../app.interface';
import Bold from '../../utils/Bold';
import { Alert } from 'reactstrap';
import { MDBBtn } from 'mdb-react-ui-kit';
import { docQr } from '../../Logics/docQr';
import { updateData } from '../../Logics/updateData';
import toast from 'react-hot-toast';
import { AddData } from '../../Logics/addData';
import { ScaleLoader } from 'react-spinners';
import { db } from '../../firebase.config';
import { collection } from 'firebase/firestore';
import { deleteData } from '../../Logics/deleteData';
import { downloadFileFromDataURL } from '../../Logics/date';

interface Props {
    task: Task,
    user:User,
    callBack:()=>void
}
const TaskDetails: React.FC<Props> = ({ task,user,callBack }) => {
// console.log(task);

const [startWorkLoading,setStartWorkLoading]=useState<boolean>(false);
  const startWork=async ()=>{
    if(startWorkLoading)return;
    const docName=collection(db,"Tasks_inprogress"+user.uid)
    try{
        setStartWorkLoading(true)
    await AddData(docName,{...task,status:'inprogress'})
    callBack()
    setStartWorkLoading(false)
    toast.success("operation successful");

    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        setStartWorkLoading(false)
        callBack();
    }
  }

  const [completeWorkLoading,setCompleteWorkLoading]=useState<boolean>(false)
  const completeWork=async ()=>{
    if(completeWorkLoading)return;
    const docName=collection(db,"Tasks_completed"+user.uid);
    try{
        setCompleteWorkLoading(true)
        const task_data:Task[]=await docQr("Tasks_inprogress",{
            max:1,
            whereClauses:[
                {
                    field:"taskId",
                    operator:"==",
                    value:task.taskId
                }
            ]
        })
        if(task_data.length>0){
        await deleteData("Tasks_inprogress"+user.uid,task_data[0].docId as string)
        }

    await AddData(docName,{...task,status:'completed'})
    callBack()
    setCompleteWorkLoading(false)
    toast.success("operation successful");
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        setCompleteWorkLoading(false)
        callBack();

    }
  }

  const downloadFile = () => {
    const { document, documentType, heading } = task;

    // const base64ToBlob = (base64:string, mime:string) => {
    //     const byteCharacters = atob(base64);
    //     const byteNumbers = new Array(byteCharacters.length);
    //     for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
    //     const byteArray = new Uint8Array(byteNumbers);
    //     return new Blob([byteArray], { type: mime });
    // };

    const mimeTypes:any = {
        "doc": "application/msword",
        "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "pdf": "application/pdf",
        "txt": "text/plain",
        "rtf": "application/rtf",
        "odt": "application/vnd.oasis.opendocument.text",
        "html": "text/html",
        "xml": "application/xml",
        "csv": "text/csv",
        "xls": "application/vnd.ms-excel",
        "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "ppt": "application/vnd.ms-powerpoint",
        "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "json": "application/json"
    };

    const mimeType = mimeTypes[documentType.toLowerCase()];

    if (!mimeType) {
        toast.error("Unsupported document type: " + documentType);
        return;
    }

    // const blob = base64ToBlob(document, mimeType);
    // const url = URL.createObjectURL(blob);
    downloadFileFromDataURL(document,heading+"_document",documentType)

};







    if (!task) return (
        <>
            No Task selected
        </>
    )
    return (
        <>
            <div className='container'>
                <Bold style={{ fontSize: 30 }}>{task.heading}</Bold>
                <br /><span>
                    {task.description}
                </span>
                <br /><br />
                <div className='d-flex align-items-center'>
                    amount : {task.amount}
                </div>
                <div className='d-flex align-items-center'>
                    priority : {task.priority}
                </div>

                <div className='d-flex align-items-center'>
                    start time : {task.startTime}
                </div>

                <div className='d-flex align-items-center'>
                    finish time : {task.finishTime}
                </div>

                <Alert>
    The document may include essential instructions for completing this task.
    <br />   
    <MDBBtn 
        color='secondary' 
        disabled={!task.document} 
        onClick={downloadFile}
    >  
      {task.document ? "Download Document":"No Document available"}
    </MDBBtn>
</Alert>

                <br />
                <div className='d-flex justify-content-center'>

                    {(task.status === 'posted' || task.status === 'start') && <MDBBtn onClick={() => {
                        startWork()
                    }} rounded  disabled={startWorkLoading}>{startWorkLoading ? <ScaleLoader color='white' height={17}/>:"Get to work"}</MDBBtn>}

                    {(task.status === 'inprogress' || task.status === 'start') && <MDBBtn disabled={completeWorkLoading} onClick={() => {
                        completeWork()
                    }} color='dark' rounded>{completeWorkLoading ? <ScaleLoader color='white' height={17}/>:"Task complete"}</MDBBtn>}

                    {(task.status === 'completed' || task.status === 'start') && <MDBBtn  color='secondary' rounded>Task completed</MDBBtn>}



                </div>


            </div>
        </>
    )
}

export default TaskDetails;