import React from 'react';
import {  Skeleton } from '@mui/material';

const UserCardSkeleton: React.FC = () => {
    return (
        <div className='userCard d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center' style={{ gap: 10 }}>
                <Skeleton variant="circular" width={40} height={40} />
                <div>
                    <Skeleton width={100} height={20} />
                    <Skeleton width={150} height={20} />
                </div>
            </div>
            <Skeleton variant="rectangular" width={24} height={24} />
        </div>
    );
}
export default UserCardSkeleton;