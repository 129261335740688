import { PausePresentation } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { MDBBtn } from 'mdb-react-ui-kit';
import React, { useEffect, useRef, useState } from 'react'
import { Send } from 'react-feather';
import { BiPaperclip } from 'react-icons/bi';
import { chatFile, chatRoom, ChatUser } from './interface';
import useUser from '../../hooks/useUser';
import { docQr } from '../../Logics/docQr';
import { AddData } from '../../Logics/addData';
import { getCurrentDateTime } from '../../Logics/date';
import { documentTypes } from '../pub/app.data';
import { PulseLoader } from 'react-spinners';
import { db } from '../../firebase.config';
import { collection } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../Logics/DateFunc';
import { Task } from '../../app.interface';

interface Props{
    room:chatRoom,
    users:ChatUser[]
}
const ChatInput:React.FC<Props>=({room,users})=>{
const [me,setMe]=useState<ChatUser>();
const {user}=useUser()
const [message,setMessage]=useState<string>("");
const [task,setTask]=useState<Task>()
useEffect(()=>{
if(users){
const user_:ChatUser[]=users.filter((e:ChatUser)=>e.uid===user.uid)
if(user_.length > 0){
    setMe(user_[0]);
}
}
},[users])

const once=useRef(false);

useEffect(()=>{
if(!once.current){
    if(sessionStorage.getItem("SharedTask")){
    setTask(JSON.parse(sessionStorage.getItem("SharedTask") as string))
    setMessage("Checkout this task")
    sessionStorage.removeItem("SharedTask")
}
once.current=true
}


},[])


const [chatFiles,setChatFiles] = useState<chatFile[]>([]);
const [isSending,setIsSending] = useState<boolean>(false)
const sendMessage=async ()=>{
    if(chatFiles.length || message || task){
    setIsSending(true);
   await AddData(collection(db,room.roomId+"_Chat"),{
        sentAt:getCurrentTimestamp(),
receivedAt:null,
sendBy:user.uid||"",
senderPicture:user.picture || "",
senderName:user.username ||"",
content:message||null,
files:chatFiles||null,
replyMessage:null,
messageId:"message"+Date.now(),
task:task||null
    })
    setChatFiles([])
    setMessage("")
    setIsSending(false);
    }
}

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
        const fileArray: chatFile[] = [];

        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target?.result as string;
                const newChatFile: chatFile = {
                    name: file.name,
                    extension: file.name.split('.').pop() || '',
                    type: file.type,
                    content: base64,
                    uploadedAt: new Date().toISOString()
                };
                setChatFiles(prevFiles => [...prevFiles, newChatFile]);
            };
            reader.readAsDataURL(file);
        });
    }
};

    return (
        <>
        <input onChange={handleFileChange} id='file' multiple type="file" hidden/>
       <div className='chatInput d-flex align-items-center'>
{me?.blocked ? <div className='text-center'>You are not allowed to send message here</div>:<>
       <IconButton  style={{cursor:"pointer"}}>
       <label htmlFor='file' style={{fontSize:'smaller'}}>{chatFiles.length>0 ? chatFiles.length +" files":""} <BiPaperclip /></label>
       </IconButton>

<input value={message} placeholder='Type message' onChange={(e)=>{
    const {target:{value}}=e;
    setMessage(value);
}}/>
<div>
    <MDBBtn onClick={()=>sendMessage()} style={{boxShadow:"none",width:'max-content'}} color='dark' >{isSending  ? <PulseLoader color='white' size={5}/>:<Send size={18} color='white'/>}</MDBBtn>
</div>
</>}
        </div>
        </>
    )
}

export default ChatInput;