import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { MDBBtn, MDBTextArea } from 'mdb-react-ui-kit';
import Bold from '../../utils/Bold';
import { getCurrentDateTime } from '../../Logics/date';
import { Task } from '../../app.interface';
import { AddData } from '../../Logics/addData';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import toast from 'react-hot-toast';
import { ScaleLoader } from 'react-spinners';

import { FileUploader } from "react-drag-drop-files";
import ManageTask from './manageTask';
import { documentTypes } from '../pub/app.data';
import { updateData } from '../../Logics/updateData';

interface Props{
    setCurrentSideCommponent:(prop:any)=>void
}
const CreateTask: React.FC<Props> = ({setCurrentSideCommponent}) => {
    const [currentTask,setCurrentTask]=useState<Task>()
    const [name, setName] = useState('');
    const [dateLine, setDateLine] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [description, setDescription] = useState('');
    const [doc,setDoc]=useState<string>();
    const [docType,setDocType]=useState<string>("");
    const [amount,setAmount]=useState<string>("");
    const [errors, setErrors] = useState({
        name: '',
        dateLine: '',
        startTime: '',
        endTime: '',
        description: '',
        doc:"",
        amount:""
    });

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {
            name: '',
            dateLine: '',
            startTime: '',
            endTime: '',
            description: '',
            doc:"",
            amount:""
        };

        if (!name) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        if (!dateLine) {
            newErrors.dateLine = 'Date line is required';
            isValid = false;
        }
        if (!startTime) {
            newErrors.startTime = 'Start time is required';
            isValid = false;
        }
        if (!endTime) {
            newErrors.endTime = 'End time is required';
            isValid = false;
        }
        if (!description) {
            newErrors.description = 'Description is required';
            isValid = false;
        }
        if (!amount) {
            newErrors.amount = 'Amount is required';
            isValid = false;
        }
    

        setErrors(newErrors);
        return isValid;
    };


useEffect(()=>{
const currentTask=sessionStorage.getItem("currentTask");
if(currentTask){
setCurrentTask(JSON.parse(currentTask as string))
}
else{
    console.log(currentTask);
    toast.error("No task selected")
    setCurrentSideCommponent(<ManageTask setCurrentSideCommponent={setCurrentSideCommponent}/>)
}
},[])
useEffect(()=>{
    setName(currentTask?.heading || "");
    setDateLine(currentTask?.dateLine || "")
    setStartTime(currentTask?.startTime || "")
    setEndTime(currentTask?.finishTime || "");
    setDescription(currentTask?.description || "");
    setDoc(currentTask?.document || "")
    setAmount(currentTask?.amount || "")
},[currentTask])

    const handleSubmit =async () => {
        if (validateInputs()) {
         
            // Submit taskData to the server here
            const readyData:Task={
             ...currentTask,   
startTime:startTime,
finishTime:endTime,
dateLine,
description:description,
heading:name,
uploadedAt: currentTask?.uploadedAt|| getCurrentDateTime(),
completed:currentTask?.completed || false,
status: currentTask?.status ||  'posted',
completionDate:currentTask?.completionDate|| null,
completedBy:currentTask?.completedBy || null,
isOverdue:currentTask?.isOverdue|| false,
taskId:currentTask?.taskId || "task"+Date.now(),
priority:currentTask?.priority || '0',
document:doc || "",
users:currentTask?.users || [],
documentType:docType,
amount:amount || ""
            }

console.log("my ready data:",readyData)
setIsLoading(true);
try{
    if(!currentTask?.docId)return new Error("Document is undefined")
   await updateData("Tasks",currentTask?.docId as string,readyData)
   toast.success("Task updated successfully");
   setCurrentSideCommponent(<ManageTask setCurrentSideCommponent={setCurrentSideCommponent}/>)
   

setName('');
setDateLine('');
setStartTime('');
setEndTime('');
setDescription('');
setDoc("")
setCurrentTask(undefined)
}
catch(err:any){
    toast.error(err?.message||"Something went wrong")
}
finally{
    setIsLoading(false);
        }
    }
    else{
        console.log("nothing is valid",)
    }
}

const handleFileChange = (file: File) => {
    
    setDocType(file.name.split('.')[1]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        setDoc(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
  };



const [isLoading,setIsLoading]=useState<boolean>(false)
    return (
        <>
            <div className='form'>
                <div className='top'>
                    <br />
                    <Bold style={{ fontSize: 25 }}>Edit Tasks</Bold>
                    <br />
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="standard"
                        color='secondary'
                        fullWidth
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    <br /><br />
                    <label style={{ fontSize: "small" }}>Date line</label>
                    <TextField
                        value={dateLine}
                        onChange={(e) => setDateLine(e.target.value)}
                        variant="standard"
                        color='secondary'
                        fullWidth
                        type='date'
                        error={!!errors.dateLine}
                        helperText={errors.dateLine}
                    />
                </div>
                <br />
                <div className='bottom'>
                    <div className='d-flex align-items-center' style={{ gap: 5 }}>
                        <div>
                            <label>Start time</label><br />
                            <TextField
                                variant='outlined'
                                type='time'
                                color='secondary'
                                size={'small'}
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                error={!!errors.startTime}
                                helperText={errors.startTime}
                            />
                        </div>
                        <div>
                            <label>End time</label><br />
                            <TextField
                                variant='outlined'
                                type='time'
                                color='secondary'
                                size={'small'}
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                error={!!errors.endTime}
                                helperText={errors.endTime}
                            />
                        </div>
                    </div>


                    <br/><br/>
                    <TextField
                        value={amount}
                        label="Amount with currency"
                        onChange={(e) => setAmount(e.target.value)}
                        color='secondary'
                        fullWidth
                        type='text'
                        error={!!errors.amount}
                        helperText={errors.amount}
                    />


                    <br /><br />
                    <MDBTextArea
                        label='Enter description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {errors.description && <div style={{ color: 'red', fontSize: 'small' }}>{errors.description}</div>}
                    <br />

                    <FileUploader handleChange={handleFileChange} name="file" types={documentTypes} />
                    <br/>
                    <div className='d-flex justify-content-center'>
                        <MDBBtn onClick={handleSubmit} style={{ background: "var(--gradient)", width: 200 }} size='lg' rounded>
                            {isLoading ? <ScaleLoader color='white' height={20}/>:"Update"}
                            
                            </MDBBtn>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateTask;
