import React, { useState } from 'react';
import { Skeleton } from '@mui/material';

interface ResponsiveImageProps {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  containerSize: number;
  style?: any;
  onClick?:()=>void
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ onClick,src, alt = '', width = 50, height = 50, containerSize, style = {} }) => {
  const [isLoading, setIsLoading] = useState(true);

  return ( 
        <img
        onClick={()=>{
         if(onClick)onClick()
        }}
          src={src}
          width={width}
          height={height}
          alt={alt}
          onLoad={() =>setIsLoading(false)}
          style={style}
        />
  )
};

export default ResponsiveImage;
