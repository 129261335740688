import React, { useState } from 'react';
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import PHeader from "../../utils/pHeader";
import "./style.css";
import { ClipLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { docQr } from "../../Logics/docQr";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { updateData } from '../../Logics/updateData';
import { User } from '../../app.interface';
import { AddData } from '../../Logics/addData';
import { db } from '../../firebase.config';
import { collection } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../Logics/DateFunc';

const Register: React.FC = () => {
    const [isCreatingPassword, setIsCreatingPassword] = useState<boolean>(false);
    const [data, setData] = useState({
        username: "",
        password: "",
        email:""
    });

    
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        return passwordRegex.test(password);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const submit = async () => {
       
    if(isLoading)return
setIsLoading(true);
        // Validate passwords
        if (!validatePassword(password1)) {
            toast.error("Password must be at least 8 characters long and include numbers, symbols, uppercase and lowercase letters.");
            return setIsLoading(false);
        }
        if (password1 !== password2) {
            toast.error("Passwords do not match.");
            return setIsLoading(false);
        }
        if(!data.email){
            toast.error("Email is required.");
            return setIsLoading(false);
        }
        if(!data.username){
            toast.error("username is required.");
            return setIsLoading(false);
        }
        try{
        //handle password
        const userData={ 
            uid:"id"+Date.now(),
            createdAt:getCurrentTimestamp(),
            updatedAt:'',
            ...data,password:password1}

//test if email exist;

const email_user=await docQr("Users",{
    max:1,
    whereClauses:[
        {
            field:"email",
            operator:"==",
            value:userData.email
        },
        {
            field:"username",
            operator:"==",
            value:userData.username
        }
    ]
})
if(email_user.length > 0){
    toast.error("User with either given email or username already exists" )
    return 
}
//end testing


await AddData(collection(db,"Users"),userData);
toast.success("Registration successfully")
window.localStorage.setItem("user",JSON.stringify(userData));
window.location.href='/'
return setIsLoading(false);

        }
        catch(err:any){
            toast.error(err?.message || "Something went wrong");
        return setIsLoading(false);
        }

    
setIsLoading(false);

    };

    return (
        <>
            <Toaster />
            <div className='loginContainer '>
                <div className='Wrapper d-flex align-items-center justify-content-center'>
                <div className='form'>
                    <br /><br />
                    <div className="d-flex align-items-center justify-content-center">
                        <img src='/logo.png' alt='' width={120} height={120} />
                    </div>
                    <PHeader style={{ textAlign: "center" }}>Register TechSpeed</PHeader>
                    
                        

                            <MDBInput
                                value={data?.username || ""}
                                size='lg'
                                label='Username'
                                onChange={(e) => setData({ ...data, username: e.target.value.split(" ").join("") })}
                            />
                            <br />
                            
                            <MDBInput
                                value={data?.email || ""}
                                size='lg'
                                label='Email'
                                onChange={(e) => setData({ ...data, email: e.target.value })}
                            /><br/>
                            <div className="password-input d-flex" style={{position:"relative"}}>
                                <MDBInput
                                    value={data?.password || ""}
                                    onChange={(e) => {
                                        setData({ ...data, password: e.target.value })
                                        setPassword1(e.target.value)
                                    }
                                    }
                                    size="lg"
                                    label='Enter password'
                                    type={showPassword ? 'text' : 'password'}
                                />
                                <IconButton style={{borderRadius:5,position:"absolute",right:0,top:2}} className="password-toggle" onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </IconButton>
                     </div>

                            
                            <br />
                            <div className="password-input d-flex" style={{position:"relative"}}>
                            <MDBInput
                                onChange={(e) => setPassword2(e.target.value)}
                                type={showPassword2 ? 'text' : 'password'}
                                size="lg"
                                value={password2}
                                label='Confirm password'
                            />
                                <IconButton onClick={()=>setShowPassword2(!showPassword2)} style={{borderRadius:5,position:"absolute",right:0,top:2}} className="password-toggle">
                                   {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                                </IconButton>
                            </div>
                     

                    <br />
                    <div className="d-flex align-items-center justify-content-center">
                        <MDBBtn rounded style={{ width: "100%" }} onClick={submit} size="lg">
                            {isLoading ? <ClipLoader size={18} color="white" /> : "Submit"}
                        </MDBBtn>
                    </div>
                    <div className='text-center'>
                
<MDBBtn color='link' onClick={()=>{
    window.location.href="/Login"
}}>Login to existing account</MDBBtn>

</div>
                </div>
            </div>
            
            </div>
        </>
    );
}

export default Register;
