import React, { useState, useEffect } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { TextField } from '@mui/material';
import Bold from '../../utils/Bold';
import { docQr } from '../../Logics/docQr';
import { ClipLoader } from 'react-spinners';
import { AddData } from '../../Logics/addData';
import { collection } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../Logics/DateFunc';
import { db } from '../../firebase.config';
import toast from 'react-hot-toast';

const CreateUser: React.FC = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({
        username: '',
        email: '',
    });

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const [isLoading,setIsLoading]=useState<boolean>(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let valid = true;
        let errors = { username: '', email: '', password: '' };

        if (!username.trim()) {
            errors.username = 'Username is required';
            valid = false;
        }

        if (!email.trim()) {
            errors.email = 'Email is required';
            valid = false;
        } else if (!validateEmail(email)) {
            errors.email = 'Invalid email address';
            valid = false;
        }

        setErrors(errors);

        if (valid) {
            // Submit the form data
            const formData = {
                username,
                email,
            };

            setIsLoading(true);
            console.log('Form Data:', formData);
            //user exist
            try{
            const userExists=await docQr("Users",{
                max:5,
                whereClauses:[
                    {
                    field:"email",
                    operator:"==",
                    value:email
                    }
                ]
            })

            console.log(userExists);
            if(userExists.length==0){
                await AddData(collection(db,"Users"),{
                        uid:"id"+Date.now(),
                        username:username,
                        email:email,
                        password:'',
                        createdAt:getCurrentTimestamp(),
                        updatedAt:''  
                })
                toast.success("User created successfully")
                setUsername("");
                setEmail("");
            }
            else{
                toast.error("User already exist with same email address")
            }
        }
        catch(err:any){
            toast.error(err?.message || "Something went wrong")
        }
        finally{
            setIsLoading(false);
        }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='form'>
                <div className='top'>
                    <br />
                    <Bold style={{ fontSize: 'large' }}>Create User</Bold>
                    <br /><br/>
                </div>
<br/>
                    <TextField
                        label="User name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        variant="standard"
                        color='secondary'
                        fullWidth
                        error={!!errors.username}
                        helperText={errors.username}
                    />
            
                <div className='bottom'>
                    <TextField
                        variant="standard"

                        // variant='outlined'
                        fullWidth
                        color='secondary'
                        label='Email address'
                        size={'medium'}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <br /><br />
                    {/* <TextField
                        variant='outlined'
                        fullWidth
                        color='secondary'
                        type='password'
                        size={'medium'}
                        label='Enter password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <br /><br /> */}
                    <div className='d-flex justify-content-center'>
                        <MDBBtn disabled={isLoading} style={{ background: "var(--gradient)", width: 200 }} size='lg' rounded type='submit'>
                        {isLoading?  <ClipLoader size={16} color='white'/>:"Submit"}
                        </MDBBtn>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default CreateUser;
