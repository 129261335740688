import React from 'react'
import Bold from '../../utils/Bold';
import { FaEllipsisV } from 'react-icons/fa';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBadge } from 'mdb-react-ui-kit';
import { Block, Work, WorkOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { Edit, Minus, Trash } from 'react-feather';
import { User } from '../../app.interface';
import { deleteData } from '../../Logics/deleteData';
import toast from 'react-hot-toast';
import { updateData } from '../../Logics/updateData';
import swal from 'sweetalert'
import { chatRoom, ChatUser } from './interface';
interface Props{
   
    user:ChatUser,
    fetchUsers:()=>void,
    room:chatRoom,
    isAdmin:boolean
}
const UserCard:React.FC<Props>=({user,fetchUsers,room,isAdmin})=>{

async function blockUser(){
    const id=toast.loading((user.blocked?"unBlocking ":"Blocking ")+user.name+"...")
    try{
if(room.roomId){
   await  updateData(room.roomId+"_Users",user.docId as string,{...user,blocked:!user.blocked})
}
fetchUsers()
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        toast.dismiss(id);
    }

}


async function removeUser(){

    const id=toast.loading("Removing "+user.name+"...")
    try{
if(room.roomId){
   await  deleteData(room.roomId+"_Users",user.docId as string)
}
fetchUsers()
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        toast.dismiss(id);
    }

}

    return (
        <>
        <div className='userCard d-flex justify-content-between align-items-center'>

            <div className='d-flex align-items-center ' style={{gap:10}}>
<Avatar/>
<div>
<Bold>{user.name}</Bold><div className='d-flex '>
<small>{user.email} </small>{user.blocked ?  <Tooltip placement='top' title={`This user can see messages but won't be able to send messages`}><MDBBadge style={{marginLeft:3}} color='danger'>blocked</MDBBadge></Tooltip>:''}</div>

    </div>
    </div> 

{isAdmin && <MDBDropdown>
      <MDBDropdownToggle tag='div' className='noEffect' split ><IconButton style={{fontWeight:"400"}}><FaEllipsisV size={15} style={{fontWeight:"300"}}/></IconButton></MDBDropdownToggle>
      <MDBDropdownMenu>

<MDBDropdownItem link onClick={()=>{
blockUser()
}}>
   <Block/>  {user.blocked ? "unblock":"block"}
</MDBDropdownItem>


<MDBDropdownItem link onClick={()=>{
removeUser()
}}>
   <Minus/>  remove
</MDBDropdownItem>

</MDBDropdownMenu>
</MDBDropdown>}

        </div>
        </>
    )
}

export default UserCard;