import React, { useState } from 'react';
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import PHeader from "../../utils/pHeader";
import "./style.css";
import { ClipLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { docQr } from "../../Logics/docQr";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { updateData } from '../../Logics/updateData';

const Main: React.FC = () => {
    const [isCreatingPassword, setIsCreatingPassword] = useState<boolean>(false);
    const [data, setData] = useState({
        username: "",
        password: "",
    });

    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        return passwordRegex.test(password);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
const [user,setUser]=useState<any>({});
    const submit = async () => {
       
    if(isLoading)return
setIsLoading(true);
    if (isCreatingPassword) {
        // Validate passwords
        if (!validatePassword(password1)) {
            toast.error("Password must be at least 8 characters long and include numbers, symbols, uppercase and lowercase letters.");
            return setIsLoading(false);
        }
        if (password1 !== password2) {
            toast.error("Passwords do not match.");
            return setIsLoading(false);
        }
        try{
        //handle password
        const userData={...user,password:password1}
await updateData("Users",user?.docId,userData);
toast.success("Password set successfully")
window.localStorage.setItem("user",JSON.stringify(userData));
window.location.reload()
return setIsLoading(false);

        }
        catch(err:any){
            toast.error(err?.message || "Something went wrong");
        return setIsLoading(false);
        }

    } else {
        // Validate username
        if (data.username.length < 3) {
            toast.error("Username must be at least 3 characters long.");
            return;
        }
        try{
        const fetchData=await docQr("Users",{
            max:1,
            whereClauses:[
                {
                    field:"username",
                    operator:"==",
                    value:data?.username?.split(" ").join("") || ""
                },
                {
                    field:"password",
                    operator:"==",
                    value:data?.password || ""
                }
            ]
        })
        console.log(fetchData);

        if(fetchData.length <= 0){
            console.log(data);
toast.error("User not found")
return setIsLoading(false);
        }
        const user=fetchData[0];
        if(user.password.trim()===''){
setIsCreatingPassword(true);
setUser(user);
        }
        else if(user.suspended){
            toast.error("You account have been suspended by admin")
            return setIsLoading(false);
        
        }
        else{
            toast.success("Login successful");
            window.localStorage.setItem("user",JSON.stringify(user));
            window.location.reload()
        }
    }
    catch(err:any){
toast.error(err?.message || "Something went wrong")        
    }

    }
setIsLoading(false);

    };

    return (
        <>
            <Toaster />
            <div className='loginContainer '>
                <div className='Wrapper d-flex align-items-center justify-content-center'>
                <div className='form'>
                    <br /><br />
                    <div className="d-flex align-items-center justify-content-center">
                        <img src='/logo.png' alt='' width={120} height={120} />
                    </div>
                    <PHeader style={{ textAlign: "center" }}>{isCreatingPassword ? "Create your password" : "Login TechSpeed "}</PHeader>
                    {isCreatingPassword ? (
                        <>
                            <div className="password-input d-flex" style={{position:"relative"}}>

                                <MDBInput
                                    size='lg'
                                    label='Create password'
                                    value={password1}
                                    type={showPassword1 ? 'text' : 'password'}
                                    onChange={(e) => setPassword1(e.target.value)}
                                />
                              
                                <IconButton style={{borderRadius:5,position:"absolute",right:0,top:2}} className="password-toggle" 
                                onClick={()=>setShowPassword1(!showPassword1)}>
                                       {showPassword1 ? <FaEyeSlash /> : <FaEye />}
                                </IconButton>
                            </div>
                            
                            <br />
                            <div className="password-input d-flex" style={{position:"relative"}}>
                            <MDBInput
                                onChange={(e) => setPassword2(e.target.value)}
                                type={showPassword2 ? 'text' : 'password'}
                                size="lg"
                                value={password2}
                                label='Confirm password'
                            />
                                <IconButton onClick={()=>setShowPassword2(!showPassword2)} style={{borderRadius:5,position:"absolute",right:0,top:2}} className="password-toggle">
                                   {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                                </IconButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <MDBInput
                                value={data?.username || ""}
                                size='lg'
                                label='Username'
                                onChange={(e) => setData({ ...data, username: e.target.value })}
                            />
                            <br />
                            <div className="password-input d-flex" style={{position:"relative"}}>
                                <MDBInput
                                    value={data?.password || ""}
                                    onChange={(e) => setData({ ...data, password: e.target.value })}
                                    size="lg"
                                    label='Enter password'
                                    type={showPassword ? 'text' : 'password'}
                                />
                                <IconButton style={{borderRadius:5,position:"absolute",right:0,top:2}} className="password-toggle" onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </IconButton>
                            </div>
                        </>
                    )}

                    <br/>
                    <div className="d-flex align-items-center justify-content-center">
                        <MDBBtn rounded style={{ width: "100%" }} onClick={submit} size="lg">
                            {isLoading ? <ClipLoader size={18} color="white" /> : "Submit"}
                        </MDBBtn>
                    </div>

                    <br />
                    <div className='text-center'>
<MDBBtn color='link' onClick={()=>{
    window.location.href="/Register"
}}>Register new account</MDBBtn>
</div>

                </div>
                </div>
            </div>
        </>
    );
}

export default Main;
