import React, { useState } from 'react';
import { TextField, Button, Avatar, IconButton, CircularProgress } from '@mui/material';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import useUser from '../../hooks/useUser';
import { updateData } from '../../Logics/updateData';
import { docQr } from '../../Logics/docQr';
import { PulseLoader } from 'react-spinners';
import toast from 'react-hot-toast';

const ProfileMain: React.FC = () => {
  const { user } = useUser();
  const [newPicture, setNewPicture] = useState<string | ArrayBuffer | null>(null);
  const [loading, setLoading] = useState(false);

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewPicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave =async  () => {
    try{
    if (newPicture) {
      setLoading(true);
      // Simulate a save action
    console.log(user)
      const user_=await docQr('Users',{
      max:1,
      whereClauses:[
      {
field:"uid",
operator:"==",
value:user.uid
      }
      ]
      })
      if(user_.length > 0){
       await updateData("Users",user_[0].docId,{ ...user, picture: newPicture });
       localStorage.setItem("user",JSON.stringify({ ...user, picture: newPicture }));
    //    setUser({ ...user, picture: newPicture })
    toast.success("Profile updated successfully")
      }
      else{
        console.log("No user",user_);
      }

    }
}
catch(err:any){
    toast.error(err.message || "Something went wrong")
}
finally{
    setLoading(false);
}
  };
  return (
    <MDBContainer>
      <MDBRow className="justify-content-center">
        <MDBCol md="6">
          <br/>
          <div className="profile text-center">
            <Avatar alt={user.username} src={newPicture || user.picture} sx={{ width: 100, height: 100, margin: 'auto' }} />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="profile-picture"
              type="file"
              onChange={handlePictureChange}
            />
            <label htmlFor="profile-picture">
              <IconButton color="primary" component="span">
                <i className="fas fa-camera"></i>
              </IconButton>
            </label>
            <h3>{user.username}</h3>
            <TextField
              label="Email"
              value={user.email}
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="username"
              value={user.username}
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />


            <TextField
              label="Created At"
              value={user.createdAt}
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />

            <MDBBtn
            rounded
              color="primary"
              onClick={handleSave}
              disabled={loading}
              style={{ marginTop: 20 }}
            >
              {loading ? <PulseLoader size={18} color='white' /> : 'Save Changes'}
            </MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProfileMain;
