import React, { useEffect ,useState} from 'react'
import "./quotes.css";
import TextTransition, { presets } from 'react-text-transition';
import Bold from '../../../utils/Bold';
import { quotesList } from '../../../utils/quotes';
import { random } from '../../../Logics/date';
const Quotes=()=>{
const [quote,setQuote]=useState();
async function changeQuotes(){
setQuote(quotesList[random(0,quotesList.length)])
}

useEffect(()=>{
    changeQuotes()
const intervalId=setInterval(()=>{
changeQuotes()
},5000);
return ()=>{
    clearInterval(intervalId);
}
},[]);

    return (
        <>
        <div className='Quotes' style={{padding:30,width:"100%",margin:"0 auto",height:"auto"}}>

                <img src='https://i.gifer.com/7LDn.gif' alt='' /><br/>
              <div className='quotesText' style={{maxWidth:"100%",maxHeight:"max-content",height:"auto"}}>
              
              <div style={{height:5 * (quote?.quote?.length/7 || 0)}}>
              <TextTransition style={{height:"auto"}} springConfig={presets.gentle}>   <Bold style={{fontSize:"18px"}}> {quote?.quote || ""}</Bold></TextTransition> 
              </div>

              <br/>
                 -<small style={{fontFamily:"cursive"}}>{quote?.author ||""}</small> 
            
                </div>


        </div>
        </>
    )
}

export default Quotes;