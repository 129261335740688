import { Cancel, Comment, CommentOutlined, Create, CreateOutlined, MenuOpen, PanoramaWideAngleSelectOutlined, Task, TaskOutlined, TimesOneMobiledata } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { AlertTriangle, Calendar, Home, Power, Settings, Terminal, User } from 'react-feather';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import LogoAndText from '../../utils/LogoAndText';
import Bold from '../../utils/Bold';
import { IconButton,Menu as MenuIcon } from '@mui/material';
import { Menu as MenuIcon2 } from 'react-feather';
import { MDBTooltip } from 'mdb-react-ui-kit';
import useInnerWidth from '../../hooks/useInnerWidth';
import { BiUserPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
interface Props{
  clickCallBack?:()=>void
}
const SideNav:React.FC<Props>=({clickCallBack})=>{
  const width=useInnerWidth()
const [toggled,setToggled]=useState<boolean>(false);
useEffect(()=>{
console.log(toggled)
},[toggled])
 const navigate=useNavigate()
 const isAdmin=useSelector((state:any)=>state.isAdmin.isAdmin)
 console.log({isAdmin})
return (<>

<Sidebar className='sideBar' rootStyles={{boxShadow:width > 700 ? "1px 1px 10px 0px lightgrey":undefined}}  collapsedWidth='65px' backgroundColor='white' collapsed={width > 700 ? toggled:false} >
  <Menu style={{height:"100vh"}} renderExpandIcon={()=><PanoramaWideAngleSelectOutlined/>}>

  {width  > 700 && 
<MenuItem style={{borderBottom:"1px solid lightgrey"}} icon={<img src="/logo.png" width={40} height={40} alt=""/>} prefix={<div style={{width:"100%"}} className='d-flex align-items-center justify-content-between'><Bold>TechSpeed</Bold></div>}></MenuItem>
}


{width > 700 &&<MDBTooltip tag={'div'} placement='right' title={toggled ? "Open Menu":"Close Menu"} >
 <MenuItem prefix={toggled ? "Open Menu":"Close Menu"} onClick={()=>setToggled(!toggled)} icon={toggled? <MenuIcon2  />:<MenuOpen/> }></MenuItem>
</MDBTooltip>}

    <MDBTooltip tag={'div'} placement='right' title="Dashboard" > 
      <MenuItem onClick={()=>{
        navigate("/")
        if(clickCallBack)clickCallBack()
      }}  prefix="Dashboard" icon={<Home/> }></MenuItem></MDBTooltip>

      {/* <MDBTooltip tag={'div'} placement='right' title="Tasks"><MenuItem icon={<TaskOutlined/>} prefix="Tasks"></MenuItem></MDBTooltip> */}

      {isAdmin && <MDBTooltip tag={'div'} placement='right' title="Admin Controls">
      <MenuItem onClick={()=>{
        navigate("/AdminControls")
        if(clickCallBack)clickCallBack()

      }} icon={<Settings/>} prefix="Admin Controls"></MenuItem>
      </MDBTooltip>}

      <MDBTooltip tag={'div'} placement='right' title="Chat Room" >
      <MenuItem onClick={()=>{
        navigate("/Chat")
        if(clickCallBack)clickCallBack()
      }} icon={<CommentOutlined/>} prefix="Chat room"></MenuItem>
      </MDBTooltip>
      

      <MDBTooltip tag={'div'} placement='right' title="Profile" >
      <MenuItem onClick={()=>{
        navigate("/Profile")
        if(clickCallBack)clickCallBack()
      }} icon={<User/>} prefix="Profile"></MenuItem>
      </MDBTooltip>
      

      <MDBTooltip tag={'div'} placement='right' title="Calender" >
      <MenuItem onClick={()=>{
        navigate("/Calendar")
        if(clickCallBack)clickCallBack()
      }} icon={<Calendar/>} prefix="Calendar"></MenuItem>
      </MDBTooltip>


      <MDBTooltip tag={'div'} placement='right' title="Log out" >
      <MenuItem onClick={()=>{
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.reload();
      }} icon={<Power/>} prefix="Logout"></MenuItem>
      </MDBTooltip>



  </Menu>
</Sidebar>

</>)
}

export default SideNav;