import React from 'react'
import Bold from '../../utils/Bold';
import { Avatar} from '@mui/material';
import { CircleOutlined} from '@mui/icons-material';
import { User } from '../../app.interface';
import { Circle } from 'react-feather';
interface Props{
    user:User,
    onClick?:(user:User)=>void
    ids:string[]
}
const UserSelectorCard:React.FC<Props>=({user,onClick,ids})=>{
    return (
        <>
        <div onClick={()=>{
           if(onClick)onClick(user)
        }} className='userCard d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center ' style={{gap:10}}>
<Avatar/>
<div>
<Bold>{user.username}</Bold><br/>
<small>{user.email}</small>
    </div>
    </div>

<div>
    {ids.includes(user.uid) ? <Circle fill='black'/>:<CircleOutlined/>}
</div>

        </div>
        </>
    )
}

export default UserSelectorCard;