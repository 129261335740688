import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserCardSkeleton from './userCard.skeleton';
import { getSingleDoc } from '../../Logics/getDoc';
import { Task, User } from '../../app.interface';
import NoData from '../../utils/noData';
import ApplicationUserCard from './ApplicationUserCard';
import { ChatUser } from '../Chat/interface';
interface Props{
    setCurrentSideCommponent:(E:React.ReactElement)=>void;
    task:Task
    
}
const ApplicationUsers:React.FC<Props>=({setCurrentSideCommponent,task})=>{
const [isLoading,setIsLoading]=useState<boolean>(false);
const [users,setUsers]=useState<User[]>([]);
const [taskUsers,setTaskUsers]=useState<ChatUser[]>()
async function fetchUsers(){

    setIsLoading(true)
    const users=await getSingleDoc("Users");
    const task_users:ChatUser[]=await getSingleDoc(task.taskId+"_Applications");
     console.log(task_users)

    setUsers(users.filter((user:any)=>task_users.filter((e:any)=>e.uid==user.uid).length > 0));
    setIsLoading(false);
}
useEffect(()=>{
fetchUsers()
},[])

    return (
        <>
<TextField variant='standard' type='search' label='Search user' fullWidth/>
<div style={{padding:20}} className='d-flex align-items-center justify-content-center'>
{!isLoading && users.length==0 && <NoData> No Application found</NoData>}
</div>

{isLoading && [1,2,3,4].map((e:any,i:number)=><UserCardSkeleton key={i}/>)}
        { !isLoading && users.map((e:any,i:number)=>(<ApplicationUserCard taskUser={taskUsers?.filter((user)=>e.uid==user.uid)[0]} task={task} key={i} user={e} fetchUsers={fetchUsers}/>))}

        </>
    )
}

export default ApplicationUsers;