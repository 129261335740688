import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material';
import ScrollBar from 'react-perfect-scrollbar';
import useInnerWidth from '../../hooks/useInnerWidth';
import { Task as TaskProps, User } from '../../app.interface';
import CardSkeleton from '../../utils/cardSkeleton';
import useUser from '../../hooks/useUser';
import Buttons, { ButtonsProps } from '../dashboard/sub/buttons';
import { fetchTasks_completed, fetchTasks_inprogress, fetchTasks_newest, fetchTasks_paid } from '../dashboard/fetchTasks';
import TaskListCard from '../dashboard/taskListCard';

interface Props{
    user:User
}
const UserTask:React.FC<Props>=({user})=>{
    const width=useInnerWidth()
    const [taskType, setTaskType] = useState<"In progress" | "Completed" | "Newest" | "Paid">("Newest")

    const [myWidth, setMyWidth] = useState<number>(0);
    const [tasks, setTasks] = useState<TaskProps[]>([]);
const [buttonsList,setButtonsList] = useState<ButtonsProps[]>([
        
        {
            name:"Newest",
            onClick:function(){
    setTaskType("Newest")
    
    markAsActive('Newest')
            },
            color:"primary"
        },
        {
            name:"In progress",
            onClick:function(){
                markAsActive("In progress")
    setTaskType("In progress")
            },
            color:"secondary"
        },
        {
            name:"Completed",
            onClick:function(){
                markAsActive("Completed")
    setTaskType("Completed")
            },
            color:"secondary"
        },
        {
            name:"Paid",
            onClick:function(){
                markAsActive("Paid")
    setTaskType("Paid")
            },
            color:"secondary"
        },
])


function markAsActive(objName:string){
    console.log(objName,buttonsList)
  const obj_ = buttonsList.map((e:ButtonsProps)=>{
if(e.name==objName)e.color='primary'
else e.color='secondary'
return e;
    })
setButtonsList([...obj_])
}
const [isLoadingTasks,setIsLoadingTasks]=useState<boolean>(true)

    const getTypeTask = async (taskType: "In progress" | "Completed" | "Newest" | "Paid") => {
        setIsLoadingTasks(true)
        let tasks_ = tasks
        switch (taskType) {
            case 'Newest':
                tasks_ = await fetchTasks_newest(user);
                break;
            case "In progress":
                tasks_ = await fetchTasks_inprogress(user);
                break;
            case "Completed":
                tasks_ = await fetchTasks_completed(user);
                break;
            case "Paid":
                tasks_ = await fetchTasks_paid(user);
                break;
            

        }
        setTasks(tasks_);
        setIsLoadingTasks(false);
    }

    useEffect(() => {
        // console.log("reload",taskTypes)
        setMyWidth((window.innerWidth - 20) - (document.querySelector(".sideNav") as HTMLDivElement)?.getBoundingClientRect()?.width || 0);
        getTypeTask(taskType)

    }, [taskType])

    useEffect(() => {
    }, [])
const [showDetails,setShowDetails]=useState<boolean>(false);
const [currentTask,setCurrentTask]=useState<TaskProps>()
    const toggleShowDetails=()=>{
        setShowDetails(!showDetails)
    }

    return (
        <div style ={{width:"100%"}}>
<Buttons buttonsList={buttonsList}/>
<br/>
<Divider/>


<div className='taskListCardContainer'>

    <ScrollBar style={{height:'70vh'}}>
        {!isLoadingTasks && tasks.length===0 && <div className='text-center' style={{padding:30}}>
            No Data Here
            </div>}
        {isLoadingTasks && [1,2,3,4,5,6].map((e:any)=>{
            return<div style={{width:"100%"}}> <CardSkeleton key={e}/></div>
        })}
    { !isLoadingTasks && tasks.map((task:any,i:number)=>(<TaskListCard getTypeTask={()=>getTypeTask(taskType)} user={user} setCurrentTask={setCurrentTask} setShowDetails={setShowDetails} task={task} key={i}/>))}
</ScrollBar>

</div>




        </div>
    )
}

export default UserTask;