import React from 'react'
import { Task } from '../../app.interface';
import { IconButton } from '@mui/material';
import { CheckSquare, Square } from 'react-feather';
interface Props{
    click:()=>void
    Task:Task
}
const MakeAsClosed:React.FC<Props>=({Task,click})=>{
    return (
        <div className='d-flex align-items-center '>
        <IconButton onClick={()=>click()}>
            {Task.isClosedOnChat ? <CheckSquare/>: <Square/>}
        </IconButton>
        closed
        </div>
    )
}

export default MakeAsClosed;