import { createSlice } from '@reduxjs/toolkit';

export const isAdminSlice = createSlice({
  name: 'isAdmin',
  initialState: {
    isAdmin: null,
  },
  reducers: {
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    clearUser: (state) => {
      state.isAdmin = null;
    },
  },
});

export const { setIsAdmin, clearIsAdmin } = isAdminSlice.actions;
export default isAdminSlice.reducer;
