import { Avatar, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserCard from './userCard';
import EditTask from './editTask';
import UserCardSkeleton from './userCard.skeleton';
import { getSingleDoc } from '../../Logics/getDoc';
import { User } from '../../app.interface';
import NoData from '../../utils/noData';
import { MDBBtn } from 'mdb-react-ui-kit';
import CreateUser from './createUser';
import EditUser from './editUser';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  }  from 'mdb-react-ui-kit'
import UserTask from './userTask';
import Bold from '../../utils/Bold';
import { sortObjectsByDate } from '../../Logics/date';
interface Props{
    setCurrentSideCommponent:(E:React.ReactElement)=>void;
    
}
const ManageUsers:React.FC<Props>=({setCurrentSideCommponent})=>{
const [isLoading,setIsLoading]=useState<boolean>(false);
const [users,setUsers]=useState<User[]>([]);
const [usersStore,setUsersStore]=useState<User[]>([]);
async function fetchUsers(){
    setIsLoading(true)
    const users=await getSingleDoc("Users");
    // console.log(users)
    setUsers(users);
    setUsersStore(users)
    setIsLoading(false);
}
useEffect(()=>{
fetchUsers()
},[])

const [currentUser,setCurrentUser]=useState<User>();
const [showUserTask,setShowUserTask]=useState<boolean>(false);

const toggleShowUserTask=()=>setShowUserTask(!showUserTask);
    return (
        <>


<MDBModal open={showUserTask} onClose={() => setShowUserTask(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <div style={{gap:10}} className='d-flex align-items-center'>
<Avatar src={currentUser?.picture}/>
                <div style={{fontSize:'smaller'}}>
                <div>{currentUser?.username}' Tasks</div> <small>{currentUser?.email}</small>
                </div>
                </div>
                
                </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShowUserTask}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
{currentUser ? <UserTask user={currentUser as User}/>:<Bold>No user selected</Bold>}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleShowUserTask}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>





<TextField onChange={(e)=>{
  const {target:{value}}=e;
  const users:User[]=usersStore.filter((user:User)=>{
      return (
          user.email?.toLowerCase().includes(value.toLowerCase()) || user.username.toLowerCase().includes(value.toLowerCase())
      )
  })
  //console.log(users,usersStore)
setUsers(sortObjectsByDate(users));
}} variant='standard' type='search' label='Search user' fullWidth/>
<div style={{padding:20}} className='d-flex align-items-center justify-content-center'>
{!isLoading && users.length==0 && <NoData> No User found<br/><MDBBtn onClick={()=>{
    setCurrentSideCommponent(<CreateUser/>)
}}>Create new user</MDBBtn></NoData>}
</div>

{isLoading && [1,2,3,4].map((e:any,i:number)=><UserCardSkeleton key={i}/>)}
        { !isLoading && users.map((e:any,i:number)=>(<UserCard key={i} taskCallBack={()=>{
            setShowUserTask(true)
            setCurrentUser(e)
        }} user={e} fetchUsers={fetchUsers} editCallback={()=>{
            sessionStorage.setItem("currentUser",JSON.stringify(e));
            setCurrentSideCommponent(<EditUser/>)
}}/>))}

        </>
    )
}

export default ManageUsers;