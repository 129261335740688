import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserCard from './userCard';
import EditTask from './editTask';
import UserCardSkeleton from './userCard.skeleton';
import { getSingleDoc } from '../../Logics/getDoc';
import { Task, User } from '../../app.interface';
import NoData from '../../utils/noData';
import { MDBBtn } from 'mdb-react-ui-kit';
import CreateUser from './createUser';
import EditUser from './editUser';
import CreateTask from './createTask';
import TaskCard from './TaskCard';
import { sortObjectsByDate } from '../../Logics/date';
interface Props{
    setCurrentSideCommponent:(E:React.ReactElement)=>void;
}
const ManageTask:React.FC<Props>=({setCurrentSideCommponent})=>{
const [isLoading,setIsLoading]=useState<boolean>(false);
const [tasks,setTasks]=useState<Task[]>([]);
const [tasksStore,setTasksStore]=useState<Task[]>([])
async function fetchTasks(){
    setIsLoading(true)
    const tasks=await getSingleDoc("Tasks");
    // console.log(tasks)
    setTasks(sortObjectsByDate(tasks));
    setTasksStore(tasks)
    setIsLoading(false);
}
useEffect(()=>{
fetchTasks()
},[])



    return (
        <>
<TextField onChange={(e)=>{
    const {target:{value}}=e;
    const tasks:Task[]=tasksStore.filter((task:Task)=>{
        return (
            task.heading?.toLowerCase().includes(value.toLowerCase()) || task.description.toLowerCase().includes(value.toLowerCase())
        )
    })
setTasks(sortObjectsByDate(tasks));
}
}
variant='standard' type='search' label='Search task' fullWidth/>
<div style={{padding:20}} className='d-flex align-items-center justify-content-center'>
{!isLoading && tasks.length==0 && <NoData> No Task found<br/><MDBBtn onClick={()=>{
    setCurrentSideCommponent(<CreateTask setCurrentSideCommponent={setCurrentSideCommponent}/>)
}}>Create new task</MDBBtn></NoData>}
</div>

{isLoading && [1,2,3,4].map((e:any,i:number)=><UserCardSkeleton key={i}/>)}


        { !isLoading && tasks.map((e:any,i:number)=>(<TaskCard key={i} setCurrentSideCommponent={setCurrentSideCommponent} fetchTasks={fetchTasks} task={e}  editCallback={()=>{
            sessionStorage.setItem("currentTask",JSON.stringify(e));
            setCurrentSideCommponent(<EditTask setCurrentSideCommponent={setCurrentSideCommponent}/>)
}}/>))}

        </>
    )
}

export default ManageTask;