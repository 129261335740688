import React, { useState } from 'react'
import Bold from '../../utils/Bold';
import { Home, Square, Users } from 'react-feather';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CheckBox, Dashboard } from '@mui/icons-material';
import { MDBTooltip } from 'mdb-react-ui-kit';
import { chatRoom } from './interface';
import useInnerWidth from '../../hooks/useInnerWidth';
import { useSelector } from 'react-redux';
import { updateData } from '../../Logics/updateData';
import { ClipLoader } from 'react-spinners';

interface Props{
    setCurrentChatRoom:(ChatRoom:chatRoom | undefined)=>void;
    room:chatRoom,
    setShowUsers:(prop:boolean)=>void

}
const ChatNav:React.FC<Props>=({setCurrentChatRoom,room,setShowUsers})=>{
    const navigate=useNavigate()
    const width=useInnerWidth()

    const isAdmin=useSelector((state:any)=>state.isAdmin.isAdmin)
    const [isLoading,setIsLoading]=useState<boolean>(false);
const toggleGroup=async ()=>{
    setIsLoading(true)
const newRoom={
    ...room,
    isPublic:!room.isPublic
}
await updateData("Rooms",room.docId as string,newRoom);
setCurrentChatRoom(newRoom)
setIsLoading(false);
}

    return (
        <>
        <div className='chatNav d-flex align-items-center justify-content-between'>
<div className='d-flex align-items-center ' style={{gap:10}}>
<img src='/logo.png' style={{width:40,height:40}} alt=''/>
<Bold>{room.name}</Bold>
</div>

<div className='d-flex'>
{isAdmin && <div className='d-flex align-items-center'>
<IconButton onClick={()=>toggleGroup()}> 
    {isLoading ? <ClipLoader size={14}/>: (room.isPublic ?<><CheckBox/> </>:<> 
    <Square/>
    </>)
}
    </IconButton>
<small>public</small>


    </div>}


{width < 700 && <MDBTooltip tag={'div'} title='Room users'>
<IconButton onClick={()=>{
    setShowUsers(true)
}}>
    <Users/>
</IconButton>
</MDBTooltip>
}

<MDBTooltip tag={'div'} title='Checkout other Rooms'>
<IconButton onClick={()=>{
    setCurrentChatRoom(undefined)
}}>
    <Dashboard/>
</IconButton>
</MDBTooltip>


</div>
            </div>
        
        </>
    )
}

export default ChatNav;