export const documentTypes = [
    "doc",
    "docx",
    "pdf",
    "txt",
    "rtf",
    "odt",
    "html",
    "xml",
    "csv",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "json"
  ];