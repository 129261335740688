import React from 'react'
import ResponsiveImage from './ResposiveImage';
interface Props{
    size?:number,
    text?:string
    showText?:boolean
    text2?:string
}
const LogoAndText:React.FC<Props>=({size=50,text='Tech',text2='Speed',showText})=>{
    const dark=typeof window!=='undefined' ? window.localStorage.getItem("darkMode"):false;
    return (<>
    
   <div onClick={()=>{
    window.location.href="/"
   }} className='logoAndText d-flex align-items-center'>
   <ResponsiveImage containerSize={size} src='/logo.png'/>
   {showText &&   <>{!dark ? <b style={{padding:10,fontSize:'large'}}><span>{text}</span> <span>{text2}</span></b>:
   <span style={{padding:10,fontWeight:"bolder",fontSize:'large'}}><span>{text}</span> <span>{text2}</span></span>}
   </> 
}

</div>
    </>)
}

export default LogoAndText;