import React, { useState } from 'react'
import Bold from '../../utils/Bold';
import { FaEllipsisV, FaPaperclip } from 'react-icons/fa';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBadge } from 'mdb-react-ui-kit';
import { Block, BookOnline, BookOnlineOutlined, Chat, ChatOutlined, SettingsApplications, TaskSharp, Work, WorkOutline } from '@mui/icons-material';
import { Edit, Paperclip, Trash } from 'react-feather';
import { Task, User } from '../../app.interface';
import { getTimeAgoString } from '../../Logics/date';
import { deleteData } from '../../Logics/deleteData';
import toast from 'react-hot-toast';
import swal from 'sweetalert'


import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import UsersSelector from '../pub/usersSelector';
import { updateData } from '../../Logics/updateData';
import ApplicationUsers from './applicationsUsers';
import { useNavigate } from 'react-router-dom';

interface Props{
    editCallback:()=>void,
    task:Task,
    fetchTasks:()=>void
    setCurrentSideCommponent:any
}
const TaskCard:React.FC<Props>=({editCallback,task,fetchTasks,setCurrentSideCommponent})=>{
const [selectedUsers,setSelectedUsers]=useState<User[]>([])
const navigate=useNavigate()
const delete_=async ()=>{
        let id:string ="";
    try{
    const yes=await swal({
        title:"Are you sure?",
        buttons:["No","Yes delete"],
        text:"Are you sure you want to delete this task? this task will be permanently deleted and this operation cannot be undone"
    })
    if(yes){
        id=toast.loading("Deleting...");

    await deleteData("Tasks",task.docId as  string);
    toast.success("Task deleted successfully")
    fetchTasks()
    }

}
catch(err:any){
    toast.error(err.message || "Something went wrong")
}
finally{
   if(id) toast.dismiss(id);
    
}
}


const [showUserSelector,setShowUserSelector]=useState<boolean>(false);
const toggleSelector=()=>setShowUserSelector(!showUserSelector);
const ShareToUser=()=>{
setShowUserSelector(true);
}

const finish=async (users:User[])=>{
    console.log(selectedUsers);
    setShowUserSelector(false);

    if(users.length==0)return toast.error("please select a user");
    try{
        const id=toast.loading("Sharing task...");
    await updateData("Tasks",task.docId as string,{...task,users:selectedUsers.map((e)=>e.uid)});
    toast.dismiss(id)
    toast.success(`Task shared to ${users.length} successfully`)

    }
    catch(err:any){
        toast.error(err.message || "Something went wrong");
    }
}














const suspendUser=async ()=>{
    const id=toast.loading("suspending "+task.heading+"..." );
    try{
    await updateData("Tasks",task.docId as string,{...task,suspended:true})
    fetchTasks()
    toast.success("Operation successful")
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
    finally{
        toast.dismiss(id);
    }
    }
    
    const unsuspendUser=async ()=>{
    
        const id=toast.loading("unsuspending "+task.heading+"..." );
        try{
        await updateData("Tasks",task.docId as string,{...task,suspended:false})
        fetchTasks()
        toast.success("Operation successful")
        }
        catch(err:any){
            toast.error(err.message || "Something went wrong")
        }
        finally{
            toast.dismiss(id);
        }
        
    }
    

    const [showApplications,setShowApplications]=useState<boolean>(false);
const toggleApplication=()=>setShowApplications(!showApplications);










    return (
        <>

      <MDBModal open={showUserSelector} onClose={() => setShowUserSelector(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Share <Bold>{task.heading}</Bold> to {selectedUsers.length} users</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleSelector}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <UsersSelector
                task={task}
                cancelCallback={()=>{
                    console.log("cancel");
                }}
                continueCallback={(users:User[])=>{
                    finish(users)
                }}
                onSelect={(users)=>{
                    console.log(users);
                    setSelectedUsers(users)

                }}
                setCurrentSideCommponent={setCurrentSideCommponent}
                />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleSelector}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
</MDBModal>












<MDBModal open={showApplications} onClose={() => setShowApplications(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle><Bold>{task.heading}</Bold> Applications</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleApplication}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <ApplicationUsers task={task} setCurrentSideCommponent={setCurrentSideCommponent}/>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleApplication}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
</MDBModal>








        <div className='userCard d-flex justify-content-between align-items-center'>

            <div className='d-flex align-items-center ' style={{gap:10}}>
<TaskSharp/>
<div>
<Bold>{task.heading} - {task.amount}</Bold> {task.suspended && <Tooltip placement='top' title='Only you can see this task'><MDBBadge style={{marginLeft:3}} color='danger'>suspended</MDBBadge></Tooltip>}<br/>
<small>status : {task.status }, uploaded {getTimeAgoString(task.uploadedAt)}</small>
    </div>
    </div>



<MDBDropdown>
      <MDBDropdownToggle tag='div' className='noEffect' split ><IconButton style={{fontWeight:"400"}}><FaEllipsisV size={15} style={{fontWeight:"300"}}/></IconButton></MDBDropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem link onClick={()=>ShareToUser()}><WorkOutline/> Share to user</MDBDropdownItem>
        <MDBDropdownItem link onClick={()=>{
            sessionStorage.setItem('SharedTask',JSON.stringify(task))
            navigate("/Chat")
        }}><ChatOutlined/> Share to chat</MDBDropdownItem>
        <MDBDropdownItem link onClick={()=>setShowApplications(true)}><BookOnlineOutlined/>Applications</MDBDropdownItem>


        <MDBDropdownItem onClick={()=>{
            editCallback()
        }} link><Edit/> Edit</MDBDropdownItem>
        <MDBDropdownItem onClick={()=>{
            if(task?.suspended){
                unsuspendUser();
            }
            else {
                suspendUser()
            }
        }}   link><Block/>  {task.suspended? 'unSuspend':'Suspend'}</MDBDropdownItem>
        <MDBDropdownItem link onClick={()=>delete_()}><Trash/> Delete</MDBDropdownItem>


      </MDBDropdownMenu>
    </MDBDropdown>

        </div>
        </>
    )
}

export default TaskCard;