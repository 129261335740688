import { MDBBtn } from 'mdb-react-ui-kit';
import React from 'react'
import useInnerWidth from '../../../hooks/useInnerWidth';
import ScrollBar from 'react-perfect-scrollbar';
export interface ButtonsProps{
name:string,
onClick:()=>void,
color: "primary" | "secondary" | "link" | "none" | "success" | "danger" | "warning" | "light" | "dark" | "muted" | "white" | "info" | "tertiary" 
}
interface Props{
    buttonsList:ButtonsProps[]
}
const Buttons:React.FC<Props>=({buttonsList})=>{
    const width=useInnerWidth()
    return (
        <>
        <div className='d-flex ' style={{minWidth:"max-content",gap:10,overflow:"auto",overflowX:"auto",overflowY:"hidden"}}>
            {buttonsList.map((button:ButtonsProps)=>{
                const {name,onClick,color}=button;
                return <>
<MDBBtn color={color} size={width <700? 'sm':undefined} onClick={onClick} rounded>{name}</MDBBtn>
                </>
            })}
{/* <MDBBtn color='primary' rounded>Newest</MDBBtn>
<MDBBtn color='secondary' rounded>Completed</MDBBtn>
<MDBBtn color='secondary' rounded>In Progress</MDBBtn> */}
        </div>
        </>
    )
}

export default Buttons;