import { getSingleDoc } from "../../Logics/getDoc";
import { Task, User } from "./../../app.interface";
export const fetchTasks_newest = async (user: User) => {
  //fetch all task for a user
  const newest_tasksList = await getSingleDoc("Tasks");
  let user_tasks_newest = newest_tasksList.filter((task: Task, i: number) => {
    return task.users.includes(user.uid);
  });
  const data_completed = await fetchTasks_completed(user);
  const data_inprogress = await fetchTasks_inprogress(user);

  return user_tasks_newest.map((task: Task) => {
    const filteredCompleted = data_completed.filter(
      (e: Task) => e.taskId === task.taskId
    );
    if (filteredCompleted.length > 0) {
      return {...filteredCompleted[0]};
    }
    const filteredInprogress = data_inprogress.filter(
      (e: Task) => e.taskId === task.taskId
    );
    if (filteredInprogress.length > 0) {
      return {...filteredInprogress[0]};
    }
    return task;
  });
};

export const fetchTasks_inprogress = async (user: User) => {
  //fetch all task for a user
  const newest_tasksList = await getSingleDoc("Tasks_inprogress" + user.uid);
  let user_tasks = newest_tasksList.filter((task: Task, i: number) => {
    return task.users.includes(user.uid);
  });
  return user_tasks;
};

export const fetchTasks_completed = async (user: User) => {
  //fetch all task for a user
  const newest_tasksList = await getSingleDoc("Tasks_completed" + user.uid);
  let user_tasks = newest_tasksList.filter((task: Task, i: number) => {
    return task.users.includes(user.uid);
  });
  return user_tasks;
};
export const fetchTasks_paid = async (user: User) => {
  //fetch all task for a user
  const newest_tasksList = await getSingleDoc("Tasks_paid" + user.uid);
  let user_tasks = newest_tasksList.filter((task: Task, i: number) => {
    return task.users.includes(user.uid);
  });
  return user_tasks;
};
