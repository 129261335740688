
import { useEffect, useState } from "react";
import { getCurrentTimestamp } from "../Logics/DateFunc";

export default function useUser() {
    const user=window.localStorage.getItem("user");
    if(user){
return {user:JSON.parse(user)}
    }
    else{
        console.info("reloading becouse we can't find a user");
        window.location.reload();
    }
    return {user:null}
}
