import React, { useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { MDBTextArea } from 'mdb-react-ui-kit';
import Bold from '../../utils/Bold';
import { getCurrentDateTime } from '../../Logics/date';
import { Task, User } from '../../app.interface';
import { AddData } from '../../Logics/addData';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import toast from 'react-hot-toast';
import { ScaleLoader } from 'react-spinners';

import { FileUploader } from "react-drag-drop-files";
import { documentTypes } from '../pub/app.data';

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import { updateData } from '../../Logics/updateData';
import UsersSelector from '../pub/usersSelector';
import { Users } from 'react-feather';
import { Chat } from '@mui/icons-material';
import { docQr } from '../../Logics/docQr';
import { useNavigate } from 'react-router-dom';
import { getCurrentTimestamp } from '../../Logics/DateFunc';
interface Props{
    setCurrentSideCommponent:(prop:any)=>void
}
const CreateTask: React.FC<Props> = ({setCurrentSideCommponent}) => {
    const [name, setName] = useState('');
    const [dateLine, setDateLine] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [description, setDescription] = useState('');
    const [doc,setDoc]=useState<string>();
    const [amount,setAmount]=useState<string>("");
    const [docType,setDocType]=useState<string>("");
    const [errors, setErrors] = useState({
        name: '',
        dateLine: '',
        startTime: '',
        endTime: '',
        description: '',
        doc:"",
        amount:""
    });

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {
            name: '',
            dateLine: '',
            startTime: '',
            endTime: '',
            description: '',
            doc:"",
            amount:""
        };

        if (!name) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        if (!dateLine) {
            newErrors.dateLine = 'Date line is required';
            isValid = false;
        }
        if (!startTime) {
            newErrors.startTime = 'Start time is required';
            isValid = false;
        }
        if (!endTime) {
            newErrors.endTime = 'End time is required';
            isValid = false;
        }
        if (!description) {
            newErrors.description = 'Description is required';
            isValid = false;
        }
        if (!amount) {
            newErrors.amount = 'Amount is required';
            isValid = false;
        }
     

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit =async () => {
        if (validateInputs()) {
         
            // Submit taskData to the server here
            const readyData:Task={
                
startTime:startTime,
finishTime:endTime,
dateLine,
description:description,
heading:name,
uploadedAt:getCurrentTimestamp(),
completed:false,
status: 'posted',
completionDate:null,
completedBy:null,
isOverdue:false,
taskId:"task"+Date.now(),
priority:'0',
document:doc || "",
documentType:docType,
users:[],
amount
            }

console.log("my ready data:",readyData)
setIsLoading(true);
try{
   await AddData(collection(db,"Tasks"),readyData)
   toast.success("Task uploaded successfully");
   
setName('');
setDateLine('');
setStartTime('');
setEndTime('');
setDescription('');
setDoc("")
setAmount("")
setShowMessage(true);
//fetch added task for sharing
const addedTask=await docQr("Tasks",{
    max:1,
    whereClauses:[
        {
            field:"taskId",
            operator:"==",
            value:readyData.taskId
        }
    ]
})
if(addedTask[0]){
    setCurrentTask(addedTask[0])
    setShowUserSelector(true)
}



}
catch(err:any){
    toast.error(err?.message||"Something went wrong")
}
finally{
    setIsLoading(false);
        }
    }
}

const handleFileChange = (file: File) => {

    console.log(file.name);

    setDocType(file.name.split('.')[1]);
    
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        setDoc(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
  };
const [isLoading,setIsLoading]=useState<boolean>(false)


const [showUserSelector,setShowUserSelector]=useState<boolean>(false);
const [selectedUsers,setSelectedUsers]=useState<User[]>([]);
const [currentTask,setCurrentTask] = useState<Task>();
const toggleSelector=()=>{
    setShowUserSelector(!showUserSelector);
}


const finish=async (users:User[])=>{//finish selecting users share task
    console.log(selectedUsers);
    setShowUserSelector(false);

    if(users.length==0)return toast.error("please select a user");
    if(!currentTask)return console.log('currentTask is null')
    try{
        const id=toast.loading("Sharing task...");
    await updateData("Tasks",currentTask.docId as string,{...currentTask,users:selectedUsers?.map((e)=>e.uid)});
    toast.dismiss(id)
    toast.success(`Task shared to ${users.length} successfully`)

    }
    catch(err:any){
        toast.error(err.message || "Something went wrong");
    }
}

const [showMessage,setShowMessage]=useState<boolean>(true);
const navigate=useNavigate()

    return (
        <>



<MDBModal open={showUserSelector} onClose={() => setShowUserSelector(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {showMessage ? <Bold>Task created</Bold>:
               <> Share <Bold>{currentTask?.heading}</Bold> to {selectedUsers?.length} users</>}
                </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleSelector}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                
                {showMessage ? <>
                <div className='d-flex justify-content-center text-center'>
<img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTosJ8TIdOguzQMC-avIaBXwHLiDJP-GENWxA&s' style={{width:150,height:150}} alt=''/>
                </div>
                <div className='d-flex justify-content-center'><Bold>Task created successfully</Bold></div>
                <Alert>
    You can now share tasks with users or group chats directly from here. Alternatively, you can do this later in the task list.
</Alert>

<br/>
<div style={{gap:10,margin:"0 auto",maxWidth:'max-content'}} className='d-flex align-items-center justify-content-between'>
<MDBBtn onClick={()=>{
    sessionStorage.setItem('SharedTask',JSON.stringify(currentTask))
    navigate("/Chat")
}}><Chat/> Share to group chat</MDBBtn>
<MDBBtn onClick={()=>{
    if(!currentTask){
        toast.error("Something went wrong sharing this task")
         setShowUserSelector(false)
         return
    }
    setShowMessage(false)
}} color='dark'><Users/> Share to users</MDBBtn>
</div>


                </>: <UsersSelector
                task={currentTask as Task}
                cancelCallback={()=>{
                    console.log("cancel");
                }}
                continueCallback={(users:User[])=>{
                    finish(users)
                }}
                onSelect={(users:User[])=>{
                    console.log(users);
                    setSelectedUsers(users)

                }}
                setCurrentSideCommponent={setCurrentSideCommponent}
                />}

            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleSelector}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
</MDBModal>



















            <div className='form'>
                <div className='top'>
                    <br />
                    <Bold style={{ fontSize: 25 }}>Create Tasks</Bold>
                    <br />
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="standard"
                        color='secondary'
                        fullWidth
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    <br /><br />
                    <label style={{ fontSize: "small" }}>Date line</label>
                    <TextField
                        value={dateLine}
                        onChange={(e) => setDateLine(e.target.value)}
                        variant="standard"
                        color='secondary'
                        fullWidth
                        type='date'
                        error={!!errors.dateLine}
                        helperText={errors.dateLine}
                    />
                </div>
                <br />
                <div className='bottom'>
                    <div className='d-flex align-items-center' style={{ gap: 5 }}>
                        <div>
                            <label>Start time</label><br />
                            <TextField
                                variant='outlined'
                                type='time'
                                color='secondary'
                                size={'small'}
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                error={!!errors.startTime}
                                helperText={errors.startTime}
                            />
                        </div>
                        <div>
                            <label>End time</label><br />
                            <TextField
                                variant='outlined'
                                type='time'
                                color='secondary'
                                size={'small'}
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                error={!!errors.endTime}
                                helperText={errors.endTime}
                            />
                        </div>
                    </div>
<br/><br/>
                    <TextField
                    label="Amount with currency"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        color='secondary'
                        fullWidth
                        type='text'
                        error={!!errors.amount}
                        helperText={errors.amount}
                    />






                    <br /><br />
                    <MDBTextArea
                        label='Enter description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {errors.description && <div style={{ color: 'red', fontSize: 'small' }}>{errors.description}</div>}
                    <br />

                    <FileUploader  handleChange={handleFileChange} name="file" types={documentTypes} />
                    <br/>
                    <div className='d-flex justify-content-center'>
                        <MDBBtn onClick={handleSubmit} style={{ background: "var(--gradient)", width: 200 }} size='lg' rounded>
                            {isLoading ? <ScaleLoader color='white' height={20}/>:"Submit"}
                            
                            </MDBBtn>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateTask;
