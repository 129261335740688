import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getSingleDoc } from '../../Logics/getDoc';
import { Task, User } from '../../app.interface';
import NoData from '../../utils/noData';
import { MDBBtn } from 'mdb-react-ui-kit';
import UserSelectorCard from './UserSelectorCard';
import UserCardSkeleton from '../Admin/userCard.skeleton';
import CreateUser from '../Admin/createUser';
import Bold from '../../utils/Bold';
interface Props{
    setCurrentSideCommponent:(E:React.ReactElement)=>void;
    heading?:string,
    continueCallback:(prop:User[])=>void;
    onSelect:(user:User[])=>void;
    cancelCallback:()=>void;
    task:Task
    
}
const UsersSelector:React.FC<Props>=({task,setCurrentSideCommponent,heading,continueCallback,onSelect,cancelCallback})=>{
const [isLoading,setIsLoading]=useState<boolean>(false);
const [users,setUsers]=useState<User[]>([]);
const [usersStore,setUsersStore]=useState<User[]>([]);
const [ids,setIds]=useState<string[]>([...(task.users.map((uid:string)=>uid) || [])]);
async function fetchUsers(){
    setIsLoading(true)
    const users=await getSingleDoc("Users");
    console.log(users)
    setUsers(users);
    setUsersStore(users);
    setIsLoading(false);
}
useEffect(()=>{
fetchUsers()
},[])



    return (
        <>
        {heading && <><Bold>{heading}</Bold> </>}
<TextField onChange={(e)=>{
    const {target:{value}}=e;
    const users=usersStore.filter((user:User)=>{
        return (
            user.username?.toLowerCase().includes(value.toLowerCase()) || user.email.toLowerCase().includes(value.toLowerCase())
        )
    })
// console.log(users);
setUsers(users);

}} variant='standard' type='search' label='Search user' fullWidth/>
<div style={{padding:20}} className='d-flex align-items-center justify-content-center'>
{!isLoading && users.length==0 && <NoData> No User found<br/><MDBBtn onClick={()=>{
    setCurrentSideCommponent(<CreateUser/>)
}}>Create new user</MDBBtn></NoData>}
</div>

{isLoading && [1,2,3,4].map((e:any,i:number)=><UserCardSkeleton key={i}/>)}
        { !isLoading && users.map((e:any,i:number)=>(<UserSelectorCard ids={ids} onClick={(user)=>{
            let id=ids;
if(!ids.includes(user.uid))id=[...ids,user.uid]
else id=[...ids.filter((id)=>id!==user.uid)]
setIds(id);
onSelect(users.filter((user)=>id.includes(user.uid)))


        }} key={i} user={e} />

    ))}
<br/>
<div className='d-flex justify-content-center'>
<MDBBtn onClick={()=>{
    continueCallback(users)
}} >Proceed with {ids.length} users</MDBBtn>
</div>
<br/>
    </>
    )
}

export default UsersSelector;