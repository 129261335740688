import React, { useEffect, useState } from 'react'
import ChatNav from './chatNav'
import "./style.css";
import SideUsers from './sideUsers';
import ScrollBar from 'react-perfect-scrollbar';
import ChatInput from './chatInput';
import UserMessage from './usersMessage';
import PersonMessage from './personMessage';
import { chatRoom, ChatUser, Message } from './interface';
import RoomSelector from './roomSelector';
import { Sheet } from 'react-modal-sheet';

import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase.config';
import useUser from '../../hooks/useUser';
import { sortMessagesBySentAt} from '../../Logics/date';
import useInnerWidth from '../../hooks/useInnerWidth';
import { ClipLoader } from 'react-spinners';
const ChatMain:React.FC=()=>{
    const [currentChatRoom,setCurrentChatRoom]=useState<chatRoom | undefined>()
const [localUsers,setLocalUsers]=useState<ChatUser[]>([]);
const [chats,setChats] = useState<Message[]>([])
const {user}=useUser();
const width=useInnerWidth()
const [isLoading,setIsLoading]=useState<boolean>(true)
 useEffect(()=>{
if(currentChatRoom){
    setIsLoading(true)
        const collectionName = currentChatRoom.roomId + "_Chat";
        const q = query(collection(db, collectionName));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages: Message[] = [];
            querySnapshot.forEach((doc) => {
                messages.push({...doc.data(),docId:doc.id} as Message);
            });
            console.log(messages);
            setChats(sortMessagesBySentAt(messages) );
            setIsLoading(false)
        })
        return () => unsubscribe();
    

}
 },[currentChatRoom])
 const [showUsers,setShowUsers]=useState<boolean>(false)
    return (<>



<Sheet isOpen={showUsers} onClose={() => setShowUsers(false)} >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>

<SideUsers setCurrentChatRoom={setCurrentChatRoom} setLocalUsers={setLocalUsers} room={currentChatRoom}/>
        

          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>




    {!currentChatRoom ?<RoomSelector setCurrentRoom={setCurrentChatRoom}/>:<div className='chatContainer d-flex m-block ' style={{gap:5,width:'100%',margin:"0 auto"}}>
    {!currentChatRoom.isPublic && <div style={{width:"30%"}} className='chatSide'>
<SideUsers setCurrentChatRoom={setCurrentChatRoom} setLocalUsers={setLocalUsers} room={currentChatRoom}/>
    </div>}

    <div style={{width:!currentChatRoom.isPublic ? '100%':"67%"}} className='chatBody'>
<ChatNav setShowUsers={setShowUsers} setCurrentChatRoom={setCurrentChatRoom} room={currentChatRoom}/>
<ScrollBar style={{height:width < 700 ?"90vh":"70vh"}}>
{isLoading ? <ClipLoader></ClipLoader>:chats.map((message:Message)=>{
return<>
{message.sendBy===user.uid && <UserMessage details={{chats,currentChatRoom,setCurrentChatRoom,setChats}} message={message}/>}
{message.sendBy!==user.uid && <PersonMessage details={{chats,currentChatRoom,setCurrentChatRoom,setChats}} message={message}/>}
</>

})}

    </ScrollBar>

<ChatInput users={localUsers} room={currentChatRoom}/>
        </div>

</div>
}

    </>)
}
export default ChatMain;