import React, { useEffect, useState } from 'react'
import "./style.css";
import CreateTask from './createTask';
import CreateUser from './createUser';
import ManageUser from './manageUser';
import EditTask from "./editTask"
import ScrollBar from 'react-perfect-scrollbar';
import { MDBCard } from 'mdb-react-ui-kit';
import { Edit3, Settings, UserPlus, Users } from 'react-feather';
import { Create, Dashboard } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import LogoAndText from '../../utils/LogoAndText';
import useInnerWidth from '../../hooks/useInnerWidth';
import ManageUsers from './manageUser';
import { Sheet } from 'react-modal-sheet';
import ManageTask from './manageTask';


const AdminMain: React.FC = () => {
    const width=useInnerWidth()
    const [currentSideCommponent,setCurrentSideCommponent]=useState<React.ReactElement>();
    const [settings,setSettings]=useState<any>([
        {
            name:"Create User",
            icon:<UserPlus size={30}/>,
        click:()=>{
            setCurrentSideCommponent(<CreateUser/>)
            if(width < 900){
                setBasicModal(true)
            }
        }
        },


{
    name:"Manage Users",
    icon:<Users/>,
    click:()=>{
        setCurrentSideCommponent(<ManageUsers setCurrentSideCommponent={setCurrentSideCommponent}/>)
        if(width < 900){
            setBasicModal(true)
        }
    }
},


        {
            name:"Create Task",
            icon:<Create/>,
        click:()=>{
            setCurrentSideCommponent(<CreateTask  setCurrentSideCommponent={setCurrentSideCommponent as any}/>)
            if(width < 900){
                setBasicModal(true)
            }
        }
        },
        
        {
            name:"Manage tasks",
            icon:<Edit3/>,
        click:()=>{
            setCurrentSideCommponent(<ManageTask setCurrentSideCommponent={setCurrentSideCommponent}/>)
            if(width < 900){
                setBasicModal(true)
            }
        }
        },
       
    ]);

useEffect(()=>{
setCurrentSideCommponent(<ManageUsers setCurrentSideCommponent={setCurrentSideCommponent}/>)
},[])

useEffect(()=>{

},[currentSideCommponent]);

const [basicModal, setBasicModal] = useState(false);
const toggleOpen = () => setBasicModal(!basicModal);
    
    return (
        <>
<br/>
{/* 
<MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle><Settings/> Admin Settings</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>{currentSideCommponent}</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}


      <Sheet isOpen={basicModal} onClose={() => setBasicModal(false)} >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content><div style={{padding:6}}>{currentSideCommponent}</div></Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>








<div className='d-flex m-fullWidth-noFlex' style={{gap:10,flexFlow:"row wrap"
}}>
            <ScrollBar style={{ maxHeight: "90vh",width:'46%' }}>

                <div className='myContainer d-flex m-fullWidth-noFlex' style={{gap:10,width:"100%",flexFlow:"row wrap"}}>

                    {settings.map((e: any, i: number) => {

                        return <div onClick={()=>{
                            e.click()
                        }} className='card' key={i}>
                            <div className='d-flex justify-content-center'>
<IconButton>{e.icon}</IconButton>
                            </div>
                            <p className='text-center'>{e.name}</p>
                        </div>
                    })}


                </div>
            </ScrollBar>


            {width > 900 && <ScrollBar style={{ maxHeight: "90vh",width:'49%' }}>
<div className='myContainer' style={{width:"100%"}}>
{currentSideCommponent}
</div>
</ScrollBar>}










</div>
        </>
    )
}

export default AdminMain;

export {
    CreateTask,
    CreateUser,
    EditTask,
    ManageUser
}