import React from 'react'
import Bold from '../../utils/Bold';
import { Avatar, Divider, IconButton } from '@mui/material';
import { Download } from 'react-feather';
import ChatTask from './chatTask';
import { Message } from './interface';
import { downloadFileFromDataURL, getTimeAgoString } from '../../Logics/date';
interface Props{
    message:Message
    details:any
}
const UserMessage:React.FC<Props>=({message,details})=>{
    return (
        <>
        <div className='d-flex justify-content-end align-items-center' style={{gap:5}}>
<div style={{maxWidth:"70%"}}>
   <div className='d-flex justify-content-end' style={{padding:0,marginBottom:5,fontSize:'smaller'}}> <span>{getTimeAgoString(message.sentAt)}</span> - <Bold>{message?.senderName}</Bold></div>


   {message.task && <ChatTask message={message} details={details} task={message.task}/>}




  {message.files.map((e)=>( <div style={{padding:2}} className='d-flex justify-content-end'>
   <div className='file d-flex align-items-center'>
<span>{e.name}</span>
<IconButton onClick={()=>{
    downloadFileFromDataURL(e.content,e.name.split(".")[0],e.extension)

}}><Download></Download></IconButton>
</div>
</div>
))}





{message.content && <div className='d-flex justify-content-end'>


    <div className='message'>
{message.content}
    </div>
    </div>}

</div>



<Avatar src={message.picture}/>

        </div>
        </>
    )
}
export default UserMessage;