import { Money, Task as TaskIcon} from '@mui/icons-material';
import React from 'react'
import { Task, User } from '../../app.interface';
import { getTimeAgoString } from '../../Logics/date';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBadge } from 'mdb-react-ui-kit';
import { IconButton } from '@mui/material';
import { FaEllipsisV } from 'react-icons/fa';
import { Trash } from 'react-feather';
import { deleteData } from '../../Logics/deleteData';
import toast from 'react-hot-toast';
import { AddData } from '../../Logics/addData';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { getCurrentTimestamp } from '../../Logics/DateFunc';

interface Props{
    task:Task,
    setShowDetails:(prop:boolean)=>void,
    setCurrentTask:(prop:Task)=>void
    getTypeTask?:()=>void,
    user?:User
}
const TaskListCard:React.FC<Props>=({task,setShowDetails,setCurrentTask,user,getTypeTask})=>{
console.log(task)
const delete_=async ()=>{
    if(!task.docId)return toast.error("task not selected");
    try{
       // console.log("Tasks_completed" + user?.uid,task.docId as string);
        const id=toast.loading("deleting from completed task...");
await deleteData("Tasks_completed" + user?.uid,task.docId as string)
if(getTypeTask)await getTypeTask()
    toast.dismiss(id);
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
}


const MarkASPaid_=async ()=>{
    if(!task.docId)return toast.error("task not selected");
    try{
        console.log("Tasks_completed" + user?.uid,task.docId as string);
        const id=toast.loading("marking task as paid...");
await deleteData("Tasks_completed" + user?.uid,task.docId as string)
await AddData(collection(db,"Tasks_paid" + (user?.uid as string || "")),{...task,status:'completed & paid',paidAt:getCurrentTimestamp()})
if(getTypeTask)await getTypeTask()
    toast.dismiss(id);
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }
}


    return (
        <>
       <div className='taskListCard d-flex align-items-center justify-content-between' onClick={()=>{
        setCurrentTask(task);
        setShowDetails(true)
       }}>
        <div className='d-flex align-items-center'>
        <div style={{background:"var(--greyBlue)",color:"blue",padding:2}}>
<TaskIcon/>
            </div>
            <div style={{flexGrow:"1"}}>

                <span>{task.heading} - {task.amount}</span>

               <div  style={{width:"100%",gap:8}} className='d-flex align-items-center' >
                 <small>{getTimeAgoString(task.uploadedAt)}</small>
                <section><small>Start <MDBBadge>{task.startTime}</MDBBadge></small>
                <small>Finish  <MDBBadge>{task.finishTime}</MDBBadge></small>
               {task.paidAt && <small>Paid at <MDBBadge>{task.paidAt}</MDBBadge></small>}
                </section>
                </div>

            </div>
            </div>




        {user && getTypeTask && task.status=='completed' && <MDBDropdown>
      <MDBDropdownToggle tag='div' className='noEffect' split ><IconButton style={{fontWeight:"400"}}><FaEllipsisV size={15} style={{fontWeight:"300"}}/></IconButton></MDBDropdownToggle>
      <MDBDropdownMenu>
       
        <MDBDropdownItem link onClick={()=>delete_()}><Trash/>Remove from completed</MDBDropdownItem>

        <MDBDropdownItem link onClick={()=>MarkASPaid_()}><Money/>Mark as paid</MDBDropdownItem>

      </MDBDropdownMenu>
    </MDBDropdown>
}


        </div>
 
        </>
    )
}

export default TaskListCard;