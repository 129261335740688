import React from 'react';
import DashboardNav from '../dashboardNav';
import DashboardBox from './dashboardBox';
import "../style.css";
import DashboardLists from './dashboardLists';
import ScrollBar from 'react-perfect-scrollbar';
const Main:React.FC=()=>{
return (<>
<ScrollBar style={{maxHeight:"90vh"}}>
<DashboardBox/>
<div style={{padding:10}}>
<DashboardLists/>
</div>
</ScrollBar>

</>
)
}
export  {Main};