import React from 'react'
import Bold from '../../utils/Bold';
import { FaEllipsisV } from 'react-icons/fa';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBadge } from 'mdb-react-ui-kit';
import { Cancel } from '@mui/icons-material';
import { Check } from 'react-feather';
import { Task, User } from '../../app.interface';
import { updateData } from '../../Logics/updateData';
import toast from 'react-hot-toast';
import { deleteData } from '../../Logics/deleteData';
import { ChatUser } from '../Chat/interface';
interface Props{
    user:User,
    fetchUsers:()=>void,
    task:Task,
    taskUser:ChatUser | undefined
}
const ApplicationUserCard:React.FC<Props>=({user,fetchUsers,task,taskUser})=>{

    console.log(task.users,user.uid,task.users.includes(user.uid))

const accept=async ()=>{
    if(task.users.includes(user.uid))return console.log("task exist")
    try{
    const id=toast.loading("Accepting application...")
await updateData('Tasks',task.docId as string,{...task,users:[...task.users,user.uid]})
toast.dismiss(id);
fetchUsers()
    }
    catch(err:any){
        toast.error(err.message || "Something went wrong")
    }

}

const decline=async ()=>{
    if(!taskUser)return
    try{
    const id=toast.loading("Declining application...")
  await  deleteData(task.taskId+"_Applications",taskUser.docId as string)
  toast.dismiss(id)
  toast.success("Declined successfully")
  fetchUsers()
}
catch(err:any){
    toast.error(err.message || "Something went wrong")
}
}

    return (
        <>
        <div className='userCard d-flex justify-content-between align-items-center'>

            <div className='d-flex align-items-center ' style={{gap:10}}>
<Avatar/>
<div>
<Bold>{user.username}</Bold><br/>
<small>{user.email} </small>{user.suspended ?  <Tooltip placement='top' title={`This user can login but won't be able to do any task`}><MDBBadge style={{marginLeft:3}} color='danger'>suspended</MDBBadge></Tooltip>:''}
    </div>
    </div> 

<MDBDropdown>
      <MDBDropdownToggle tag='div' className='noEffect' split ><IconButton style={{fontWeight:"400"}}><FaEllipsisV size={15} style={{fontWeight:"300"}}/></IconButton></MDBDropdownToggle>
      <MDBDropdownMenu>

<MDBDropdownItem onClick={()=>{
    accept()
}} link> <Check/>{task.users.includes(user.uid) ?"Approved": "Approve"}
</MDBDropdownItem>
{!task.users.includes(user.uid)  ? <MDBDropdownItem onClick={()=>{
    decline()
}} link> <Cancel/> Decline
</MDBDropdownItem>:<></>}


      
     
      </MDBDropdownMenu>
    </MDBDropdown>

        </div>
        </>
    )
}

export default ApplicationUserCard;