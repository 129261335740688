import { IconButton, Skeleton, TextField } from '@mui/material';
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react'
import { Trash, Users } from 'react-feather';
import { chatRoom } from './interface';
import Bold from '../../utils/Bold';
import { getSingleDoc } from '../../Logics/getDoc';
import toast from 'react-hot-toast';
import { collection } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { getCurrentTimestamp } from '../../Logics/DateFunc';
import { AddData } from '../../Logics/addData';
import { ClipLoader } from 'react-spinners';
import NoData from '../../utils/noData';
import { deleteData } from '../../Logics/deleteData';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
interface Props{
    setCurrentRoom:(ChatRoom:chatRoom)=>void
}
const RoomSelector:React.FC<Props>=({setCurrentRoom})=>{
const [roomName,setRoomName]=useState<string>("");
const [isLoading,setIsLoading]=useState<boolean>(true);
const [rooms,setRooms]=useState<chatRoom[]>([]);

const isAdmin=useSelector((state:any)=>state.isAdmin.isAdmin)

async function fetchRooms(){
    setIsLoading(true)
    try{
    const rooms=await getSingleDoc("Rooms");


    
    setRooms(rooms);
    }
    catch(err:any){
        toast.error(err.message  || "Something went wrong")
    }
    finally{
        setIsLoading(false)
    }
}

async function delete_(room:chatRoom){
    const id_=await swal({
        title:"Are you sure?",
        text:"Are you sure want to delete this room?",
        buttons:["Delete","No"]
    })
    if(!id_){
    const id=toast.loading("deleting...");
    try{
if(room.docId)await deleteData('Rooms',room.docId as string)
if(room.roomId)await deleteData("Chat",room.roomId);
toast.dismiss(id);
fetchRooms()
    }
catch(err:any){
toast.error(err.message || "something went wrong")
}
    }

}
useEffect(()=>{
fetchRooms()
},[]);

const [creatingRooms,setCreatingRooms]=useState<boolean>(false);
const createRoom=async ()=>{
    setCreatingRooms(true)
    try{
    const docId=await AddData(collection(db,"Rooms"),{
        name:roomName,
        roomId:"room"+Date.now(),
        createdAt:getCurrentTimestamp(),
    })
    setRoomName("")
    fetchRooms()
}
catch(err:any){
    toast.error(err.message  || "Something went wrong")
}
finally{
setCreatingRooms(false)
}
}

const navigate=useNavigate()
    return (
        <>
        <br/>
        <br/>
        <br/>
        <div style={{
            background:'white',
            top:'0',
            position:'fixed',
            padding:10,
            width:"100%"
        }} onClick={()=>{
    navigate('/')
        }} className='chatNav d-flex align-items-center justify-content-between'>
<div className='d-flex align-items-center ' style={{gap:10}}>
<img src='/logo.png' style={{width:40,height:40}} alt=''/>
<Bold>Home</Bold>
</div>

<div className='d-flex'>
<div className='d-flex align-items-center'>



    </div>


</div>
            </div>
        

        <div className='roomSelector'>
<Bold style={{fontSize:25}}>Select a room</Bold>

{!isLoading && rooms.length===0 && <div className='d-flex justify-content-start'>
<NoData>No room created yet</NoData>
</div>}

<div className='d-flex ' style={{flexFlow:"row wrap",gap:10}}>
{isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((e, i) => (
                    <div key={i} className='room text-center' style={{ padding: 10 }}>
                        <Skeleton variant="circular" width={35} height={35} />
                        <br />
                        <Skeleton variant="text" width={100} />
                    </div>
                ))
            ) : (
                rooms.map((room, i) => (
                    <div
                        key={i}
                        className='room text-center'
                       
                        style={{ padding: 10,position:'relative',width:150 }}
                    >
                      {isAdmin &&  <IconButton style={{position:"absolute",left:"0"}} onClick={()=>{
                            delete_(room);
                        }}><Trash size={14}></Trash></IconButton>}


                        <Users  onClick={() => {
                            setCurrentRoom(room);
                        }} size={35} /><br />
                        <span onClick={() => {
                            setCurrentRoom(room);
                        }} >{room.name}</span>

                    </div>
                ))
            )}

</div>


<br/>
{isAdmin && 
<div className='d-flex align-items-center' style={{maxWidth:500,gap:5}}>
<TextField onChange={(e)=>{
    const {target:{value}}=e;
    console.log(value);
    setRoomName(value);
}} label='Enter new room name' variant='standard'  style={{flexGrow:'1'}}/>
<MDBBtn onClick={()=>{
    createRoom()
}}>{creatingRooms ? <ClipLoader color='white' size={15}></ClipLoader>:"Create"}</MDBBtn>
</div>}

            </div>
        </>
    )
}


export default RoomSelector;