
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import SideNav from './Components/pub/sideNav';
import { MDBBtn } from 'mdb-react-ui-kit';
import DashboardNav from './Components/dashboardNav';
import {Main as DashboardMain }from './Components/dashboard/main';
import { Route, Routes,BrowserRouter as Router, useLocation } from 'react-router-dom';
import AdminMain from './Components/Admin/main';
import toast, { Toaster } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import Main from './Components/Login/main';
import ChatMain from './Components/Chat/main';
import CalendarMain from './Components/Calendar/main';
import Register from './Components/Login/Register';
import { useDispatch } from 'react-redux';
import { docQr } from './Logics/docQr';
import useUser from './hooks/useUser';
import { AddData } from './Logics/addData';
import { db } from './firebase.config';
import { collection } from 'firebase/firestore';
import { User } from './app.interface';
import { setIsAdmin } from './Components/features/isAdminSlice';
import ProfileMain from './Components/Profile/main';
import swal from 'sweetalert';

function App() {
const [userExists,setUserExists]=useState(window.localStorage.user)
const [user,setUser]=useState<User>()
const dispatch = useDispatch();


async function checkAdmin(user:User){
  if(!user || !user.uid)return
const result=await docQr("Admins",{
  max:1,
  whereClauses:[
    {
      field:"uid",
      operator:"==",
      value:user.uid
    }
  ]
})
if(result.length > 0){
  dispatch(setIsAdmin(true))
}

const user_=await docQr("Users",{
  max:1,
  whereClauses:[
    {
      field:"uid",
      operator:"==",
      value:user.uid
    }
  ]
})

if(user_.length > 0){
  localStorage.setItem("user",JSON.stringify(user_[0]));
  if(user_[0]?.suspended){
    swal({
      title:"Sorry you have been suspended from using this software contact admin for more information"
    }).then(()=>{
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    })
  }
}
}


useEffect(()=>{
  if(userExists){
  const user=JSON.parse(userExists)
  setUser(user)
    checkAdmin(user)
  }
},[])
if(!userExists && window.location.pathname!=='/Register'){
  return <Main/>
}
else if(window.location.pathname==='/Register'){
return <Register/>
}
  return (
    <>
    <Toaster/>
<Router>
  <div className='body'>
<div className='sideNav'>
<SideNav/>
</div>

<div className='bodyMain'>
<DashboardNav/>

<Routes>
            <Route path="/" element={<DashboardMain />} /> {/* Updated element prop */}
            <Route path="/AdminControls" element={<AdminMain/>}/>
            <Route path="/Profile" element={<ProfileMain/>}/>
            <Route path="/Chat" element={<ChatMain/>}/>
            <Route path="/Calendar" element={<CalendarMain/>}/>
            <Route path="/MakeMeAdminNow" element={<MakeAdmin user={user as User}/>}/>
                </Routes>

</div>
</div>
</Router>

</>
  );
}


interface Props{
  user:User
}


const MakeAdmin:React.FC<Props>=({user})=>{
  const [isLoading,setIsLoading]=useState<boolean>();
//const {user}=useUser()
  async function makeAdminFunc(){
    setIsLoading(true);
  await AddData(collection(db,"Admins"),{username:user.username,uid:user.uid})
  setIsLoading(false);
  toast.success("make admin successful")
  }
  return (
    <>
    <MDBBtn onClick={()=>{
      makeAdminFunc()
    }}>{isLoading ? "Processing":"Make me admin"}</MDBBtn>
    </>
  )
}
export default App;
